import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const name = 'user'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,

            ['new' + N + 'SubmitLabel']: 'Save',
            ['restore' + N + 'SubmitLabel']: 'Restore',
            ['save' + N + 'SubmitLabel']: 'Save',
            ['cancel' + N + 'Text']: 'Cancel',
            ['delete' + N + 'Text']: 'Delete',
            ['delete' + Ns + 'Text']: 'Delete users',
            ['ban' + N + 'Text']: 'Ban',
            ['approve' + N + 'Text']: 'Approve',
            ['protection' + N + 'Text']: 'Mark as admin',
            ['removeProtection' + N + 'Text']: 'Remove admin rights',
            ['select' + N + 'Text']: 'Select',
            ['add' + N + 'Text']: 'Add',

            [ns + 'SortRelevance']: 'Relevance',

            loginSubmitLabel: 'Login',
            logoutSubmitLabel: (s) => s > 0 ? 'Logout in ' + s + ' seconds...' : 'Logout',
            signupSubmitLabel: 'Sign up',
            forgotPasswordSubmitLabel: 'Send reset email',
            resetPasswordSubmitLabel: 'Reset password',
            changeEmailSubmitLabel: 'Change email address',
            changePasswordSubmitLabel: 'Change password',
            emailConfirmationSubmitLabel: 'Confirm email address',
            deleteAccountLabel: 'Delete account',
            emailConfirmationSendAgainSubmitLabel: 'Resend confirmation email',
            acceptTermsLabel: 'Accept',
            acceptPrivacyLabel: 'Accept',
            termsWord: 'EULA',
            privacyWord: 'Privacy Policy',
            signupQuestion: 'Don\'t have a profile yet?',
            nextToSignup: 'Proceed to Sign Up',
            loginQuestion: 'Already have a profile?',
            nextToLogin: 'Proceed to Login',

            [n + 'Overview']: 'Overview',
            [n + 'OverviewForAdmin']: 'Admin',
            [n + 'OverviewSubscriptions']: 'Subscriptions',

            betaTesterBecomeSubscribe: 'Subscribe',

            betaTesterDialogClose: 'Close',
            betaTesterDialogCardsTitle: 'In the works:',
            betaTesterDialogCard1: 'Semi-automated\nMITRE attacks',
            betaTesterDialogCard2: 'More integrations\nand imports',
            betaTesterDialogCard3: 'UI improvements\nand more...',

            subscribeNewReleaseBecomeSubscribe: 'Subscribe',

            subscribeNewReleaseDialogClose: 'Close',
            subscribeNewReleaseDialogCardsTitle: 'In the works:',
            subscribeNewReleaseDialogCard1: 'Semi-automated\nMITRE attacks',
            subscribeNewReleaseDialogCard2: 'More integrations\nand imports',
            subscribeNewReleaseDialogCard3: 'UI improvements\nand more...',

            nextIsPurchaseButtonLabel: 'Next step'

        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Users',
            ['my' + Ns + 'Menu']: 'My users',
            ['new' + N + 'Menu']: 'New user',
            [ns + 'Menu']: 'Users',
            ['deleted' + Ns + 'Menu']: 'Deleted users',
            ['banned' + Ns + 'Menu']: 'Banned users',
            ['protection' + Ns + 'Menu']: 'Admins',
            ['missingData' + Ns + 'Menu']: 'Missing data',
            ['lowAuthorStatus' + Ns + 'Menu']: '',

            ['edit' + N + 'Menu']: 'Edit',
            ['delete' + N + 'Menu']: 'Delete',
            ['ban' + N + 'Menu']: 'Ban',
            ['approve' + N + 'Menu']: 'Approve',
            ['protection' + N + 'Menu']: 'Mark as admin',
            ['removeProtection' + N + 'Menu']: 'Remove admin rights',

            loginMenu: 'Login',
            signupMenu: 'Sign up',
            changeData: 'Change data',
            invoiceSettings: 'Invoice data',
            notificationSettings: 'Notifications',
            changeEmail: 'Change email address',
            emailConfirmation: 'Confirm email address',
            changePassword: 'Change password',
            forgotPasswordMenu: 'Reset password',
            deleteAccount: 'Delete account',
            logoutMenu: 'Logout',
            profileSettings: 'Account settings',
            otherSettings: 'Other',

            accountSettingsMenu: 'Settings',
            dashboardMenu: 'User account',
            userProfileMenu: 'Profile',
            loginMoreMenu: 'More',

            imagesAndDocuments: 'Images and documents'
        },
        messages: {
            ...messages,

            ['new' + N + 'SuccessMessage']: 'The new user has been saved',
            ['save' + N + 'SuccessMessage']: 'The user has been saved',
            ['delete' + N + 'Success']: 'The user has been deleted',
            ['delete' + Ns + 'Success']: 'The users have been deleted',
            ['delete' + N + 'Question']: 'Are you sure you want to delete the user?',
            ['delete' + Ns + 'Question']: 'Are you sure you want to delete the users?',
            ['ban' + N + 'Success']: 'The user has been banned',
            ['ban' + N + 'Question']: 'Are you sure you want to ban the user?',
            ['approve' + N + 'Success']: 'The user has been approved',
            ['approve' + N + 'Question']: 'Are you sure you want to approve the user?',
            ['protection' + N + 'Success']: 'The user has been marked as admin',
            ['protection' + N + 'Question']: 'Are you sure you want to mark the user as admin?',
            ['removeProtection' + N + 'Success']: 'The admin rights have been removed',
            ['removeProtection' + N + 'Question']: 'Are you sure you want to remove the admin rights?',
            ['thereAreNo' + Ns]: 'There are no users',

            notificationSettingsInfo: 'What would you like to receive email notifications about?',
            forgotPasswordSuccessMessage: 'The message has been sent to your email address',
            forgotPasswordInfo: 'Send a password reset link via email to reset the new password',
            resetPasswordSuccessMessage: 'The password has been updated',
            changePasswordSuccessMessage: 'The password has been updated',
            changeEmailSuccessMessage: 'The email has been saved',
            changeDataSuccessMessage: 'Your data has been saved',
            invoiceSettingsSuccessMessage: 'Your data has been saved',
            notificationSettingsSuccessMessage: 'Your data has been saved',
            emailConfirmationSuccessMessage: 'The email address has been confirmed',
            emailConfirmationSendAgainSuccessMessage: 'The email confirmation key has been resent',
            emailConfirmationSendAgainInfo: 'Send a confirmation email to validate the email address',
            emailConfirmationInfo: 'A confirmation email has been sent with the email confirmation key. Please copy the confirmation key here or click on the confirmation link to verify your email address',
            deleteAccountSuccessMessage: 'The account has been deleted',
            deleteAccountQuestion: 'Are you sure you want to delete the account?',
            deleteAccountSuccess: 'The account has been deleted',
            deleteAccountInfo: 'The account can be restored within 30 days if the user logs in',
            thisIsNotYourResetPasswordKey: 'This is not the reset password key for this account, please log out.',
            thisIsNotYourEmailConfirmationKey: 'This is not the email confirmation key for this account, please log out',
            thisIsNotYourNewsletter2ConfirmationKey: 'This is not the beta tester programme confirmation key for this account, please log out',

            avatarWelcomeText: 'Hello,',

            betaTesterBecomeContent: 'Please help us to create a better product and sign up for our Beta programme! You may access pre-release builds or get invited to our user research sessions where you can share your feedback with us on our newest tools.',
            betaTesterDialogFooterContent: 'We sent you an email, in this message please click on the confirmation link to verify your subscription. Thank you!',
            betaTesterSuccessDialogContent: 'You have successfully registered for our Beta tester programme.\n\nWe will contact you in email periodically when we have new versions available to test.',

            subscribeNewReleaseBecomeContent: 'Subscribe to our new release channel and we\'ll let you know when new stuffs arrive.',
            subscribeNewReleaseDialogFooterContent: 'We sent you an email, in this message please click on the confirmation link to verify your subscription. Thank you!',
            subscribeNewReleaseSuccessDialogContent: 'You have successfully registered for new releases.\n\nWe will contact you in email periodically when we have new versions available.',

            nextIsPurchaseContent: 'You don\'t have a license yet, you can buy your first license in the next step',

            [n + 'TotpCopyClipboardSuccess']: 'TOTP secret key copied to clipboard',
            [n + 'TotpCopyClipboardError']: 'Clipboard copying is not supported on this device',

            ['validation' + N + 'InvoiceCountry']: (example) => 'Supported tax id formats: ' + example

        },
        routes: {
            ...routes,
            ['user' + Ns + 'Route']: '/' + ns,
            [n + 'Route']: '/' + n,
            [ns + 'Route']: '/' + ns
        },
        titles: {
            ...titles,
            [n + 'Title']: 'User',
            [ns + 'Title']: 'Users',
            ['new' + N + 'Title']: 'New user',
            ['edit' + N + 'Title']: 'Edit user',
            ['user' + Ns + 'Title']: 'Users',
            ['userDeleted' + Ns + 'Title']: 'Deleted users',
            ['userBanned' + Ns + 'Title']: 'Permanently deleted users',
            ['my' + Ns + 'Title']: 'My users',
            ['myDeleted' + Ns + 'Title']: 'Deleted users of mine',
            ['myBanned' + Ns + 'Title']: 'Permanently deleted users of mine',

            ['dialogDelete' + N + 'Title']: 'Delete user',
            ['dialogBan' + N + 'Title']: 'Ban user',
            ['dialogApprove' + N + 'Title']: 'Approve user',
            ['dialogProtection' + N + 'Title']: 'Mark user as admin',
            ['dialogRemoveProtection' + N + 'Title']: 'End user\'s admin status',

            ['dialogDelete' + Ns + 'Title']: 'Delete users',

            ['statusBanned' + N + 'Title']: 'Banned',
            ['statusDeleted' + N + 'Title']: 'Deleted',
            ['statusAuthorDeleted' + N + 'Title']: 'Author deleted',
            ['statusMissingData' + N + 'Title']: 'Missing data',
            ['statusApproved' + N + 'Title']: 'Approved',
            ['statusFeatured' + N + 'Title']: 'Admin',
            ['statusCreated' + N + 'Title']: 'Registered',

            ['banned' + Ns + 'Title']: 'Permanently deleted users',
            ['deleted' + Ns + 'Title']: 'Deleted users',
            ['missingData' + Ns + 'Title']: 'Missing data',
            ['protected' + Ns + 'Title']: 'Admins',
            ['lowAuthorStatus' + Ns + 'Title']: '',

            ['select' + Ns]: 'Select users',
            ['select' + N]: 'Select user',

            ['get' + N + 'Description']: (name) => name + '\'s page',

            loginTitle: 'Log into your profile',
            loginTitleOnPage: 'Welcome!',
            loggedOutTitleOnPage: 'You logged out successfully.',
            accountTitle: 'Settings',
            changeDataTitle: 'Change data',
            invoiceSettingsTitle: 'Invoice data',
            notificationSettingsTitle: 'Notifications',
            changeEmailTitle: 'Change email address',
            emailConfirmationTitle: 'Confirm email address',
            signupTitle: 'Sign up',
            signupTitleOnPage: 'As a first step, you need to create an account:',
            logoutTitle: 'Logout',
            forgotPasswordTitle: 'Forgot password',
            resetPasswordTitle: 'Reset password',
            changePasswordTitle: 'Change password',
            deleteAccountTitle: 'Delete account',
            profileSettingsTitle: 'Account settings',
            otherSettingsTitle: 'Other',

            dialogDeleteAccountTitle: 'Delete account',
            userProfileTitle: 'Profile',
            dashboardTitle: 'User account',
            acceptTermsTitle: 'Accept EULA',
            acceptPrivacyTitle: 'Accept Privacy Policy',
            socialLoginTitle: 'Login with your social account',

            betaTesterBecomeTitle: 'Become a beta tester',
            betaTesterDialogTitle: 'Beta tester programme',
            betaTesterSuccessDialogTitle: 'Thanks for your interest!',

            subscribeNewReleaseBecomeTitle: 'Want to know about new features and new releases?',
            subscribeNewReleaseDialogTitle: 'New releases channel',
            subscribeNewReleaseSuccessDialogTitle: 'Thanks for your interest!',

            nextIsPurchaseTitle: 'Purchase your first license'

        }
    }
}
