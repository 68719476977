import React, { useContext } from 'react'

import SettingsIcon from 'octopwn-ui/dist/common/src/svg/SettingsIcon'
import DownloadIcon from 'octopwn-ui/dist/common/src/svg/DownloadIcon'

import capitalize from '../../utils/capitalize'
import { defaultGetPageName } from '../../components/Post/utils'
import { CustomTableRow } from '../../components/Posts/Components/CustomTableRow'

import Posts from '../page/Content/Posts'
import PostContext from '../../components/Post/context'

import defaultPostTypeConfig from '../post'

import getConstants from './constants'
import getMenu from './menu'

import Content from './Content'

const postTypeConfig = {
    getStatusManager: function getStatusManager(p = {}) {
        return defaultPostTypeConfig.getStatusManager({
            ...p,
            config: {
                requiredDataForStatus: {
                    licenseId: { type: String }
                },
                ...(p.config) ? p.config : {}
            }
        })
    },
    getConstants: getConstants,
    setContents: function setContents(p = {}) {
        const name = 'license'

        const { wapp } = p

        const r = defaultPostTypeConfig.setContents({
            ...p,
            name,
            urlParams: [
                '/page/:pagination',
                '/sort/:sort',
                '/limit/:limit'
            ]
        })

        const contentProps = wapp.contents.get(name)

        wapp.contents.add({
            [name]: {
                ...contentProps,
                robots: () => {
                    return 'noindex,nofollow'
                }
            }
        })

        return r
    },
    requestForUserPage: async function requestForUserPage(p = {}) {

        const { res, name = 'license' } = p

        const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'

        const wappResponse = res.wappResponse
        const route = wappResponse.route
        const { params } = route
        const { _id, page, pageType } = params

        const isForMe = (page === ns && (pageType === 'forme') && _id)

        return await defaultPostTypeConfig.requestForUserPage({
            ...p,
            name,
            ...isForMe ? {
                pageType: '',
                requestName: name + 'FindManyLicenseUser',
                transformRequestParams: ({ requestParams }) => {
                    return requestParams
                }
            } : {}
        })
    },
    getComponent: function(p) {

        const name = 'license'

        const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'

        const { context, appContext } = p
        const { routes } = appContext

        const { res, req } = context
        const route = res.wappResponse.route
        const requestPath = route.requestPath
        const user = req.wappRequest.user

        const isAdmin = user && user._status_isFeatured

        if ((requestPath.startsWith(routes[name + 'Route'])) || (requestPath.startsWith(routes[ns + 'Route']))) {

            const pages = {
                content: Content
            }

            if (requestPath.startsWith(routes[ns + 'Route']) && !isAdmin) {
                return null
            }

            const requestKeys = [name + 'FindById']
            const post = requestKeys.map((requestName) => res.wappResponse.store.getState('res.responses.' + requestName)).find((r) => r?._id)
            const isAuthor = ((user?._id && user._id.toString() === post?._author) || (user?._id && user._id.toString() === post?._author?._id))

            const isAuthorOrAdmin = (isAuthor || isAdmin)
            const licenseUser = (user?._id && user._id.toString() === post?.licenseUserId)

            if (requestPath.startsWith(routes[name + 'Route']) && requestPath === routes[name + 'Route'] + '/new') {
                return null
            }

            if (requestPath.startsWith(routes[name + 'Route']) && !isAuthorOrAdmin && !licenseUser) {
                return null
            }

            return defaultPostTypeConfig.getComponent({
                ...p,
                name,
                archiveComponentProps: {
                    tableProps: {
                        showLabelTitle: true
                    },
                    getMenu: (p) => getMenu(p).map((m) => {
                        delete m.featured
                        return m
                    }),
                    selectable: () => false,
                    selectFunctions: [],
                    type: 'table',
                    MenuItemComponent: (props) => {
                        return <CustomTableRow
                            {...props}
                            {...props.itemProps.post.paperColor ? {
                                color: props.itemProps.post.paperColor,
                                PaperProps: { blur: true }
                            } : {}}
                        />
                    }
                },
                componentProps: {
                    pages,
                    showAuthor: ({ user }) => user?._status_isFeatured,
                    //showCreatedDate: ({ user, author }) => user?._id && user._id === author?._id,
                    getProps: ({ pageName }) => {

                        if (pageName === 'content') {

                            return {
                                showAuthor: () => false,
                                PaperComponentProps: {
                                    color: undefined,
                                    elevation: 0,
                                    style: {
                                        padding: 0
                                    }
                                },
                                disableTitleContainer: true
                            }

                        }

                        return {}
                    },
                    getMenu,
                    getPageName: (props) => {
                        const r = defaultGetPageName(props)
                        const isAdmin = props.user && props.user._status_isFeatured
                        if ((r === 'edit' && !isAdmin) || r === 'new') {
                            return null
                        }
                        return r
                    }
                }
            })
        }
    },
    userPageFunctions: function(p) {

        const { context, appContext, postContext } = p

        const n = 'license'
        const ns = (n.endsWith('y')) ? n.slice(0, -1) + 'ies' : n + 's'
        const Ns = capitalize(ns)
        //const N = capitalize(n)

        const { res } = context

        const wappResponse = res.wappResponse
        const route = wappResponse.route
        const { params } = route

        const { _id, page, pageType } = params

        const isForMe = (page === ns && (pageType === 'forme') && _id)

        const r = defaultPostTypeConfig.userPageFunctions({
            ...p,
            name: n,
            nsPageProps: {
                tableProps: {
                    showLabelTitle: true
                },
                getMenu,
                selectable: () => false,
                selectFunctions: [],
                type: 'table',
                ListToolsProps: {
                    SearchProps: {
                        placeholder: appContext.labels[n + 'SearchLabel']
                    }
                },
                MenuItemComponent: (props) => {
                    return <CustomTableRow
                        {...props}
                        {...props.itemProps.post.paperColor ? {
                            color: props.itemProps.post.paperColor,
                            PaperProps: { blur: true }
                        } : {}}
                        startIcon={isForMe ? <DownloadIcon /> : <SettingsIcon />}
                    />
                },
                ...isForMe ? {
                    requestName: n + 'FindManyLicenseUser'
                } : {}
            }
        })

        //const {userStatusManager} = appContext;

        function addMenuItems(props) {

            const { appContext } = props
            const { menus, routes } = appContext

            return [
                {
                    label: function(p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                        return (isAuthor) ? menus['my' + Ns + 'Menu'] : menus[postContext.name + Ns + 'Menu']
                    },
                    role: function(p) {
                        const isAdmin = p.user && p.user._status_isFeatured
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                        return (isAuthor || isAdmin) && p.post?.emailConfirmed
                    },
                    order: 1,
                    items: [
                        {
                            label: function(p) {
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return (isAuthor) ? menus['my' + Ns + 'OwnedMenu'] : menus[postContext.name + Ns + 'OwnedMenu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] : routes[postContext.name + Ns + 'Route']
                            },
                            role: function(p) {
                                const isAdmin = p.user && p.user._status_isFeatured
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return (isAdmin || isAuthor)
                            }
                        },
                        {
                            label: function(p) {
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return (isAuthor) ? menus['my' + Ns + 'ForMeMenu'] : menus[postContext.name + Ns + 'ForMeMenu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] + '/forme' : routes[postContext.name + Ns + 'Route'] + '/forme'
                            },
                            role: function(p) {
                                const isAdmin = p.user && p.user._status_isFeatured
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return (isAdmin || isAuthor)
                            }
                        }
                    ]
                }
            ]
        }

        const parentRoute = appContext.routes[postContext.name + 'Route']

        function addContentMenuItems() {
            return [
                {
                    role: function(p) {
                        const { user, post } = p
                        const isAdmin = user && user._status_isFeatured
                        const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id))
                        return (isAdmin || isAuthor) && post?.licensesCount
                    },
                    order: 20,
                    Component: () => {

                        const postContext = useContext(PostContext)

                        const { user, post } = postContext

                        const isAuthor = ((user?._id && user?._id === post?._author) || (user?._id && user?._id === post?._author?._id))

                        return (
                            <Posts
                                name={n}
                                type={'table'}
                                label={isAuthor ? appContext.titles['my' + Ns + 'Title'] : appContext.titles['user' + Ns + 'Title']}
                                nextLabel={isAuthor ? appContext.labels['moreFromMy' + Ns + 'Title'] : appContext.labels['moreFromUser' + Ns + 'Title']}
                                LabelProps={{
                                    variant: 'h6'
                                }}
                                requestParams={{
                                    page: 1,
                                    perPage: 5,
                                    _author: post?._id
                                }}
                                posts={[]}
                                postsSlice={[]}
                                fromRequest={true}
                                enableCache={false}
                                carousel={false}
                                NextProps={{
                                    href: parentRoute + ((post?._id) ? '/' + post._id + appContext.routes[postContext.name + Ns + 'Route'] : appContext.routes[postContext.name + Ns + 'Route']),
                                    color: undefined,
                                    size: 'default',
                                    variant: 'text'
                                }}
                                NextButtonContainerProps={{
                                    style: {
                                        justifyContent: 'flex-end'
                                    }
                                }}
                                nextButtonShowsOnlyIfCountGreaterThanLength={true}
                                disableMenu={false}
                                disableAvatars={true}
                                getMenu={getMenu}
                                tableProps={{
                                    showLabelTitle: true
                                }}
                                MenuItemComponent={(props) => {
                                    return <CustomTableRow
                                        {...props}
                                        {...props.itemProps.post.paperColor ? {
                                            color: props.itemProps.post.paperColor,
                                            PaperProps: { blur: true }
                                        } : {}}
                                        startIcon={<SettingsIcon />}
                                    />
                                }}
                            />
                        )
                    }
                },
                {
                    role: function(p) {
                        const { user, post } = p
                        const isAdmin = user && user._status_isFeatured
                        const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id))
                        return (isAdmin || isAuthor) && post?.licensesCount
                    },
                    order: 21,
                    Component: () => {

                        const postContext = useContext(PostContext)

                        const { user, post } = postContext

                        const isAuthor = ((user?._id && user?._id === post?._author) || (user?._id && user?._id === post?._author?._id))

                        return (
                            <Posts
                                name={n}
                                type={'table'}
                                label={isAuthor ? appContext.titles['my' + Ns + 'ForMeTitle'] : appContext.titles['user' + Ns + 'ForMeTitle']}
                                nextLabel={isAuthor ? appContext.labels['moreFromMy' + Ns + 'Title'] : appContext.labels['moreFromUser' + Ns + 'Title']}
                                LabelProps={{
                                    variant: 'h6'
                                }}
                                requestParams={{
                                    page: 1,
                                    perPage: 5,
                                    _author: post?._id
                                }}
                                requestName={n + 'FindManyLicenseUser'}
                                posts={[]}
                                postsSlice={[]}
                                fromRequest={true}
                                enableCache={false}
                                carousel={false}
                                NextProps={{
                                    href: parentRoute + ((post?._id) ? '/' + post._id + appContext.routes[postContext.name + Ns + 'Route'] : appContext.routes[postContext.name + Ns + 'Route']),
                                    color: undefined,
                                    size: 'default',
                                    variant: 'text'
                                }}
                                NextButtonContainerProps={{
                                    style: {
                                        justifyContent: 'flex-end'
                                    }
                                }}
                                nextButtonShowsOnlyIfCountGreaterThanLength={true}
                                disableMenu={false}
                                disableAvatars={true}
                                getMenu={getMenu}
                                tableProps={{
                                    showLabelTitle: true
                                }}
                                MenuItemComponent={(props) => {
                                    return <CustomTableRow
                                        {...props}
                                        {...props.itemProps.post.paperColor ? {
                                            color: props.itemProps.post.paperColor,
                                            PaperProps: { blur: true }
                                        } : {}}
                                        startIcon={<DownloadIcon />}
                                    />
                                }}
                            />
                        )
                    }
                }
            ]
        }

        function getPageName({ user, post, page }) {

            const isAdmin = user && user._status_isFeatured
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id))
            const isAuthorOrAdmin = (isAdmin || isAuthor)

            return (
                (page === ns && !pageType) ||
                (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1) ||
                (page === ns && pageType === 'forme' && isAuthorOrAdmin) ||
                (page === ns && pageType === 'deleted' && isAuthorOrAdmin) ||
                (page === ns && pageType === 'banned' && isAdmin)
            ) ? (isAuthorOrAdmin) ? page : 'notFound' : null

        }

        function getDashBoardTitle({ user, post, page }) {

            const isAdmin = user && user._status_isFeatured
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id))
            const isAuthorOrAdmin = (isAdmin || isAuthor)

            if (page === ns && pageType === 'deleted' && !isAdmin) {
                return null
            }

            if (page === ns && pageType === 'forme' && isAuthorOrAdmin) {
                return (isAuthor) ? appContext.titles['my' + Ns + 'ForMeTitle'] : appContext.titles[postContext.name + Ns + 'ForMeTitle']
            }

            return r.getDashBoardTitle({ user, post, page })

        }

        return {
            ...r,
            addMenuItems,
            addContentMenuItems,
            getPageName,
            getDashBoardTitle
        }
    },
    adminMenu: function(p) {

        const name = 'license'

        const defaultAdminMenu = defaultPostTypeConfig.adminMenu({ ...p, name })
        defaultAdminMenu.order = 1
        defaultAdminMenu.items = defaultAdminMenu.items.filter((item, i) => {
            return (i === 0 || i === 2 || i === 4 || i === 6 || i === 7)
        })
        return defaultAdminMenu
    }
}

export default postTypeConfig
