import React from 'react'

import OrderStatus from './OrderStatus'

export const tableIcons = {}

export const tableComponents = {
    OrderStatus: {
        props: {
            value: 10,
            name: 'order'
        },
        Component: OrderStatus
    }
}
