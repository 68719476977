import React from 'react'

import defaultPostTypeConfig from '../post'
import getConstants from './constants'
import getMenu from './menu'

import New from './New'
import Content from './Content'
import { tableComponents, tableIcons } from './Content/tableComponents'
import { defaultGetPageName } from '../../components/Post/utils'

const postTypeConfig = {
    getStatusManager: function getStatusManager(p = {}) {
        return defaultPostTypeConfig.getStatusManager({
            ...p,
            config: {
                requiredDataForStatus: {
                    paymentId: { type: String },
                    total: { type: Number }
                },
                ...(p.config) ? p.config : {}
            }
        })
    },
    getConstants: getConstants,
    setContents: function setContents(p = {}) {
        const name = 'payment'

        const { wapp } = p

        const r = defaultPostTypeConfig.setContents({
            ...p,
            name,
            urlParams: [
                '/page/:pagination',
                '/sort/:sort',
                '/limit/:limit'
            ]
        })

        const contentProps = wapp.contents.get(name)

        wapp.contents.add({
            [name]: {
                ...contentProps,
                robots: () => {
                    return 'noindex,nofollow'
                }
            }
        })

        return r
    },
    requestForUserPage: async function requestForUserPage(p = {}) {
        return await defaultPostTypeConfig.requestForUserPage({
            ...p,
            name: 'payment'
        })
    },
    getComponent: function(p) {

        const name = 'payment'

        const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'

        const { context, appContext } = p
        const { routes } = appContext

        const { res, req } = context
        const route = res.wappResponse.route
        const requestPath = route.requestPath
        const user = req.wappRequest.user

        const isAdmin = user && user._status_isFeatured

        if ((requestPath.startsWith(routes[name + 'Route'])) || (requestPath.startsWith(routes[ns + 'Route']))) {

            const pages = {
                content: Content,
                new: New
            }

            if (requestPath.startsWith(routes[ns + 'Route']) && !isAdmin) {
                return null
            }

            const requestKeys = [name + 'FindById']
            const post = requestKeys.map((requestName) => res.wappResponse.store.getState('res.responses.' + requestName)).find((r) => r?._id)
            const isAuthor = ((user?._id && user._id.toString() === post?._author) || (user?._id && user._id.toString() === post?._author?._id))

            const isAuthorOrAdmin = (isAuthor || isAdmin)

            if (requestPath.startsWith(routes[name + 'Route']) && requestPath !== routes[name + 'Route'] + '/new' && !isAuthorOrAdmin) {
                return null
            }

            return defaultPostTypeConfig.getComponent({
                ...p,
                name,
                archiveComponentProps: {
                    type: 'table',
                    tableProps: {
                        components: tableComponents,
                        icons: tableIcons,
                        showLabelTitle: true
                    },
                    getMenu: (p) => getMenu(p).map((m) => {
                        delete m.featured
                        return m
                    }),
                    selectable: () => false,
                    selectFunctions: []
                },
                componentProps: {
                    pages,
                    showAuthor: ({ user }) => user?._status_isFeatured,
                    //showCreatedDate: ({ user, author }) => user?._id && user._id === author?._id,
                    getProps: ({ pageName }) => {

                        if (pageName === 'content') {

                            return {
                                showAuthor: () => false,
                                PaperComponentProps: {
                                    color: undefined,
                                    elevation: 0,
                                    style: {
                                        padding: 0
                                    }
                                }
                            }

                        }

                        return {}
                    },
                    getMenu,
                    getPageName: (props) => {
                        const r = defaultGetPageName(props)
                        if (r === 'edit') {
                            return null
                        }
                        return r
                    }
                }
            })
        }
    },
    userPageFunctions: function(p) {

        const { context } = p

        const n = 'payment'
        const ns = (n.endsWith('y')) ? n.slice(0, -1) + 'ies' : n + 's'

        const { res } = context

        const wappResponse = res.wappResponse
        const route = wappResponse.route
        const { params } = route
        const { pageType } = params

        const r = defaultPostTypeConfig.userPageFunctions({
            ...p, name: n, nsPageProps: {
                tableProps: {
                    components: tableComponents,
                    icons: tableIcons,
                    showLabelTitle: true
                },
                getMenu,
                selectable: () => false,
                selectFunctions: [],
                type: 'table'
            }
        })

        //const {userStatusManager} = appContext;

        function addMenuItems() {
            return []
        }

        function addContentMenuItems() {
            return []
        }

        function getPageName({ user, post, page }) {

            const isAdmin = user && user._status_isFeatured
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id))
            const isAuthorOrAdmin = (isAdmin || isAuthor)

            return (
                (page === ns && !pageType) ||
                (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1) ||
                (page === ns && pageType === 'deleted' && isAuthorOrAdmin) ||
                (page === ns && pageType === 'banned' && isAdmin)
            ) ? (isAuthorOrAdmin) ? page : 'notFound' : null

        }

        return {
            ...r,
            addMenuItems,
            addContentMenuItems,
            getPageName
        }
    },
    adminMenu: function(p) {

        const name = 'payment'

        const defaultAdminMenu = defaultPostTypeConfig.adminMenu({ ...p, name })
        defaultAdminMenu.order = 10
        defaultAdminMenu.items = defaultAdminMenu.items.filter((item, i) => {
            return (i === 0 || i === 1 || i === 2 || i === 6 || i === 7)
        })
        return defaultAdminMenu
    }
}

export default postTypeConfig
