import React, { startTransition, useContext, useLayoutEffect, useRef } from 'react'

import { copyObject } from 'wapplr/dist/common/utils'
import { WappContext } from 'wapplr-react/dist/common/Wapp'
import getUtils from 'wapplr-react/dist/common/Wapp/getUtils'
import { updateCache } from '../../../components/Post/Edit/updateCache'

export function getOctopwnPaymentsFromCookieFunctions({ wapp, req, res }) {

    function setCookie(name, value, exDays) {
        const d = new Date()
        d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000))
        const expires = 'expires=' + d.toUTCString()
        document.cookie = name + '=' + value + ';' + expires + ';path=/'
    }

    function getCookie(cname) {
        let name = cname + '='
        let decodedCookie = decodeURIComponent(wapp.target === 'node' ? req.headers.cookie : document.cookie)
        let ca = decodedCookie.split(';')
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) === ' ') {
                c = c.substring(1)
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length)
            }
        }
        return ''
    }

    function setOctopwnPaymentsToCookie() {
        const paymentsData = res.wappResponse.store.getState('octopwnPayments') || {}
        setCookie('octopwn-payments', JSON.stringify(paymentsData), 365)
    }

    function getOctopwnPaymentsFromCookie() {
        let data = getCookie('octopwn-payments')
        try {
            data = JSON.parse(data)
        } catch (e) {

        }
        if (data?.currency) {
            const d = {}
            const enabledKeys = ['currency']
            enabledKeys.forEach((key) => {
                const value = data[key]
                if (
                    (key === 'currency' && ['eur', 'usd', 'chf'].indexOf(value) > -1)
                ) {
                    d[key] = value
                }
            })
            return data
        }
        return {}
    }

    return {
        setOctopwnPaymentsToCookie,
        getOctopwnPaymentsFromCookie
    }

}

function createStates({ wapp, res, actionType, getOctopwnPaymentsFromCookie }) {

    if (!wapp.states.stateManager.actions.octopwnPayments) {
        wapp.states.stateManager.actions.octopwnPayments = function({ type, name, value }) {
            return {
                type: type || actionType,
                payload: {
                    name,
                    value
                }
            }
        }
    }
    if (!wapp.states.stateManager.reducers.octopwnPayments) {
        wapp.states.stateManager.reducers.octopwnPayments = function(state = {}, action) {
            switch (action.type) {
                case actionType:
                    return {
                        ...state,
                        [action.payload.name]: (action.payload.value && typeof action.payload.value == 'object') ? copyObject(action.payload.value) : action.payload.value
                    }
                default:
                    return state
            }
        }
    }
    if ((wapp.target === 'node' || (res.wapplrReactEndType === 'pipe' || res.wapplrReactEndType === 'pipeWaitForAll'))) {
        const data = getOctopwnPaymentsFromCookie()
        if (data.currency) {
            if (res.wappResponse.store.getState('octopwnPayments.currency') !== data.currency) {
                res.wappResponse.store.dispatch(wapp.states.runAction('octopwnPayments', {
                    name: 'currency',
                    value: data.currency
                }))
            }
        }
    }

}

export async function updateProducts({ context, refPostType = 'product' }) {

    const { wapp, req, res } = context

    const userStatusManager = wapp.getTargetObject().postTypes.findPostType({ name: 'user' }).statusManager
    const statusManager = wapp.getTargetObject().postTypes.findPostType({ name: refPostType }).statusManager

    const response = await wapp.requests.send({
        requestName: refPostType + 'FindMany',
        args: {
            filter: {
                _operators: {
                    _status: { gt: statusManager.getMinStatus() - 1 },
                    _author_status: {
                        gt: userStatusManager.getMinStatus() - 1
                    }
                }
            }
        },
        req,
        res
    })

    if (response && response[refPostType + 'FindMany'] && response[refPostType + 'FindMany'].items?.length) {

        response[refPostType + 'FindMany'].items.forEach((post) => {
            updateCache({ wapp, res, post })
        })

    }

}

export async function updateCart({ context, appContext, name = 'order', refPostType = 'product' }) {

    const { wapp, req, res } = context

    const utils = getUtils(context)
    const user = req.wappRequest.user

    const { storage } = appContext
    const storageName = user?._id ? name + '_' + user._id : name

    function getStorageData() {
        const localStorageData = storage()[storageName] || {}
        const memoData = storage(undefined, true)[storageName] || {}
        return { ...localStorageData, ...memoData }
    }

    let storageData = getStorageData()
    const cartItems = (storageData['record.cart']) ? JSON.parse(storageData['record.cart']) : []

    let newCart = []
    const newProducts = [...cartItems]

    await Promise.all(cartItems.map(async (cartItem, i) => {
        if (cartItem?._id) {
            const requestName = refPostType + 'FindById'
            let response = await utils.sendRequest({
                requestName,
                args: { _id: cartItem._id }
            })
            if (typeof response[requestName] !== 'undefined') {
                response = response[requestName]
            }
            if (response?._id) {
                newProducts[i] = { ...response }
                updateCache({ wapp, res, post: response })
            } else {
                newProducts[i] = null
            }
        } else {
            newProducts[i] = null
        }
    }))

    newProducts.forEach((product) => {

        if (product) {

            const cartItem = cartItems.find((cartItem) => cartItem?._id === product?._id)

            newCart.push({
                ...cartItem ? cartItem : {},
                ...product,
                q: cartItem?.q || 1
            })

        }

    })

    newCart = newCart.filter((cartItem) => cartItem?._id && cartItem?.q > 0)

    newCart = newCart.map((cartItem) => {

        const enableKeys = [
            'price',
            'priceText',
            'price1',
            'price2',
            'price1Usd',
            'price2Usd',
            'price1Chf',
            'price2Chf',
            'currency',
            'currencyText',
            'q',
            'subtitle',
            '_id',
            'thumb',
            'title',
            'subtitle',
            'disableProduct',
            'available',
            'paperColor',
            'infoText',
            'infoTextShort'
        ]

        return {
            ...enableKeys.reduce((newItem, key) => {
                newItem[key] = cartItem[key]
                return newItem
            }, {})
        }

    })

    storageData = getStorageData()

    storage({
        [storageName]: {
            ...storageData,
            ['record.cart']: JSON.stringify(newCart)
        }
    })

    await appContext.template.current?.actions?.updateMiniCart()

}

export default function InitOctopwnPaymentsStates({ handlers = {}, actionType = 'SET_OCTOPWN_PAYMENTS', children }) {

    const context = useContext(WappContext)

    const { wapp, req, res } = context

    const {
        getOctopwnPaymentsFromCookie
    } = getOctopwnPaymentsFromCookieFunctions({ wapp, req, res })

    const prevState = useRef({})

    function getState() {
        let v = res.wappResponse.store.getState('octopwnPayments')
        if ((wapp.target === 'node' || (res.wapplrReactEndType === 'pipe' || res.wapplrReactEndType === 'pipeWaitForAll')) && !v?.currency) {
            const data = getOctopwnPaymentsFromCookie()
            if (data.currency) {
                v = data
            }
        }
        return v
    }

    function getCurrency() {
        let v = res.wappResponse.store.getState('octopwnPayments.currency')
        if ((wapp.target === 'node' || (res.wapplrReactEndType === 'pipe' || res.wapplrReactEndType === 'pipeWaitForAll')) && typeof v === 'undefined') {
            const data = getOctopwnPaymentsFromCookie()
            if (data.currency) {
                v = data.currency
            }
        }
        return v
    }

    function setCurrency(value) {
        let v = res.wappResponse.store.getState('octopwnPayments.currency')
        if (v !== value) {
            res.wappResponse.store.dispatch(wapp.states.runAction('octopwnPayments', { name: 'currency', value }))
        }
    }

    function applyCurrency(prevState, nextState, onChange) {
        const prevCurrency = prevState.currency
        const nextCurrency = nextState.currency
        if (prevCurrency !== nextCurrency) {
            onChange(nextState)
        }
    }

    function subscribe() {

        createStates({ wapp, res, actionType, getOctopwnPaymentsFromCookie })

        prevState.current = getState() || {}

        return res.wappResponse.store.subscribe(function({ type, payload }) {

            if (type === actionType && payload.name === 'currency') {
                const nextState = getState() || {}
                applyCurrency(prevState.current, nextState, (nextState) => {
                    prevState.current = getState() || {}
                    if (handlers['changeCurrency']) {
                        handlers['changeCurrency'](payload, nextState)
                    }
                })
            }

        })

    }

    function initStates() {

        createStates({ wapp, res, actionType, getOctopwnPaymentsFromCookie })

        prevState.current = getState() || {}
        const currency = getCurrency() || 'eur'

        const nextState = { currency }

        applyCurrency(prevState.current, nextState, (nextState) => {
            setCurrency(nextState.currency)
            prevState.current = getState() || {}
            if (handlers['changeCurrency']) {
                handlers['changeCurrency']({ value: nextState.currency, name: 'currency' }, nextState)
            }
        })
    }

    if ((typeof window === 'undefined' ? 'node' : 'web') === 'web') {
        let firstRender = !(wapp.states.stateManager.actions.octopwnPayments)
        let unsubscribe
        if (firstRender) {
            startTransition(() => {
                initStates()
                unsubscribe = subscribe()
            })
        }
        useLayoutEffect(() => {
            if (unsubscribe) {
                unsubscribe()
                unsubscribe = null
            }
            return subscribe()
        }, [])
    } else {
        initStates()
    }

    return (
        <>
            {children}
        </>
    )

}
