import capitalize from '../../utils/capitalize'
import defaultPostTypeConfig from '../post'

export default function getConstants(p = {}) {

    const name = 'product'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 's' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Save',
            ['restore' + N + 'SubmitLabel']: 'Restore',
            ['save' + N + 'SubmitLabel']: 'Save',
            ['cancel' + N + 'Text']: 'Cancel',
            ['delete' + N + 'Text']: 'Delete',
            ['delete' + Ns + 'Text']: 'Delete products',
            ['ban' + N + 'Text']: 'Ban',
            ['approve' + N + 'Text']: 'Approve',
            ['protection' + N + 'Text']: 'Mark as protected',
            ['removeProtection' + N + 'Text']: 'Remove protection',
            ['select' + N + 'Text']: 'Select',
            ['add' + N + 'Text']: 'Add',
            ['moreFromMy' + Ns + 'Title']: 'More from my products',
            ['moreFromUser' + Ns + 'Title']: 'More products',

            [n + 'SearchTextPlaceholder']: 'Plan name',
            [n + 'SearchSubmitLabel']: 'Search',
            [n + 'RefreshSubmitLabel']: 'Refresh results',

            [n + 'PageInfoPerPage']: ({ startItem, endItem, allItem, perPage, currentPage }) => {
                if (currentPage >= 2 && (endItem - startItem <= perPage)) {
                    return `${currentPage}. page - ${allItem} products total`
                }
                if (allItem <= perPage || currentPage < 2) {
                    return `${allItem} products total`
                }
                return `${startItem} - ${endItem}, ${allItem} products total`
            },

            [ns + 'SortRelevance']: 'Relevance',

            [n + 'OverviewProducts']: 'Products',
            [n + 'OverviewImportMenu']: 'Import products',
            [n + 'OverviewExport']: 'Export products',
            [n + 'OverviewUpdateFromWholesale']: 'Update from wholesale database',

            [n + 'CurrencyText']: ({ currency = 'eur' }) => currency === 'chf' ? 'CHF' : currency === 'usd' ? '$' : '€'
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Products',
            ['my' + Ns + 'Menu']: 'My products',
            ['new' + N + 'Menu']: 'New product',
            [ns + 'Menu']: 'Products',
            ['deleted' + Ns + 'Menu']: 'Deleted products',
            ['banned' + Ns + 'Menu']: 'Permanently deleted products',
            ['protection' + Ns + 'Menu']: 'Protected products',
            ['missingData' + Ns + 'Menu']: 'Missing data',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Author with low status',

            ['edit' + N + 'Menu']: 'Edit',
            ['delete' + N + 'Menu']: 'Delete',
            ['ban' + N + 'Menu']: 'Ban',
            ['approve' + N + 'Menu']: 'Approve',
            ['protection' + N + 'Menu']: 'Mark as protected',
            ['removeProtection' + N + 'Menu']: 'Remove protection'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'The new product has been saved',
            ['save' + N + 'SuccessMessage']: 'The product has been saved',
            ['delete' + N + 'Success']: 'The product has been deleted',
            ['delete' + Ns + 'Success']: 'The products have been deleted',
            ['delete' + N + 'Question']: 'Are you sure you want to delete the product?',
            ['delete' + Ns + 'Question']: 'Are you sure you want to delete the products?',
            ['ban' + N + 'Success']: 'The product has been permanently deleted',
            ['ban' + N + 'Question']: 'Are you sure you want to permanently delete the product?',
            ['approve' + N + 'Success']: 'The product has been approved',
            ['approve' + N + 'Question']: 'Are you sure you want to approve the product?',
            ['protection' + N + 'Success']: 'The product has been marked as protected',
            ['protection' + N + 'Question']: 'Are you sure you want to mark the product as protected?',
            ['removeProtection' + N + 'Success']: 'The protection has been removed',
            ['removeProtection' + N + 'Question']: 'Are you sure you want to remove the protection?',
            ['thereAreNo' + Ns]: 'There are no products',
            [n + 'ImportNumAffected']: (numAffected) => numAffected + ' products have been saved'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Product',
            [ns + 'Title']: 'Products',
            ['new' + N + 'Title']: 'New product',
            ['edit' + N + 'Title']: 'Edit product',
            ['user' + Ns + 'Title']: 'Products',
            ['userDeleted' + Ns + 'Title']: 'Deleted products',
            ['userBanned' + Ns + 'Title']: 'Permanently deleted products',
            ['my' + Ns + 'Title']: 'My products',
            ['myDeleted' + Ns + 'Title']: 'My deleted products',
            ['myBanned' + Ns + 'Title']: 'My permanently deleted products',

            ['dialogDelete' + N + 'Title']: 'Delete product',
            ['dialogBan' + N + 'Title']: 'Delete product permanently',
            ['dialogApprove' + N + 'Title']: 'Approve product',
            ['dialogProtection' + N + 'Title']: 'Mark product as protected',
            ['dialogRemoveProtection' + N + 'Title']: 'Remove product protection',

            ['dialogDelete' + Ns + 'Title']: 'Delete products',

            ['statusBanned' + N + 'Title']: 'Permanently deleted',
            ['statusDeleted' + N + 'Title']: 'Deleted',
            ['statusAuthorDeleted' + N + 'Title']: 'Author deleted',
            ['statusMissingData' + N + 'Title']: 'Missing data',
            ['statusApproved' + N + 'Title']: 'Approved',
            ['statusFeatured' + N + 'Title']: 'Protected',
            ['statusCreated' + N + 'Title']: 'Created',

            ['banned' + Ns + 'Title']: 'Permanently deleted products',
            ['deleted' + Ns + 'Title']: 'Deleted products',
            ['missingData' + Ns + 'Title']: 'Missing data',
            ['protected' + Ns + 'Title']: 'Protected products',
            ['lowAuthorStatus' + Ns + 'Title']: 'Author with low status',

            ['select' + Ns]: 'Select licenses',
            ['select' + N]: 'Select license'
        }
    }
}
