import React, { lazy, Suspense, useContext, useEffect, useRef, useState } from 'react'
import { WappContext } from 'wapplr-react/dist/common/Wapp'
import Button from 'octopwn-ui/dist/common/src/components/Button'

const Lazy = lazy(() => import('./sync').catch((error) => {
    console.log('[APP] Component Failed Loading:', error)
    return { default: () => <div /> }
}))

export const CustomCard = ({ disableIntersectionObserver, ...props }) => {

    const context = useContext(WappContext)
    const { req, res } = context

    const reference = useRef()
    const listener = useRef({})

    const history = res.wappResponse.store.getState('app.history') || []
    const lastItem = history[history.length - 1]

    const protocol = req.wappRequest.protocol
    const hostname = req.wappRequest.hostname

    const [show, setShow] = useState(
        (props.index <= 3 || typeof props.index === 'undefined') ||
        (lastItem?.key && lastItem.key !== 'initial' && history.length > 1) ||
        disableIntersectionObserver ||
        (typeof window !== 'undefined' && protocol + '://' + hostname + req.url !== window?.location.href)
    )

    function removeListener() {
        if (listener.current.remove) {
            listener.current.remove()
            listener.current.remove = null
        }
    }

    async function onIntersected(e, value) {
        if (show !== value && value) {
            setShow(value)
            removeListener()
        }
    }

    function addIntersectionListeners() {
        if (reference.current && !show && typeof IntersectionObserver !== 'undefined') {
            const resizeObserver = new IntersectionObserver(
                ([e]) => {
                    onIntersected(null, !e.intersectionRatio < 1)
                },
                { rootMargin: '45% 0px 45% 0px', threshold: [0] }
            )
            resizeObserver.observe(reference.current)
            return function removeEventListener() {
                resizeObserver.disconnect()
            }
        }
    }

    const CardProps = {
        Reference: () => {
            useEffect(() => {
                removeListener()
                if (!show) {
                    const removeIntersectionListeners = addIntersectionListeners()
                    if (listener.current.remove) {
                        listener.current.remove = removeIntersectionListeners
                    }
                }
                return () => {
                    removeListener()
                }
            })

            if (show) {
                return null
            }

            return (
                <div ref={reference} style={{ width: '100%', height: '100%', position: 'absolute', left: 0, top: 0 }} />
            )
        }
    }

    return (
        <>
            <Suspense fallback={<div></div>}>
                {show ?
                    <Lazy {...props} {...CardProps} /> :
                    <Button
                        style={props.isGallery ? { aspectRatio: '1/1' } : { minHeight: '600px' }}
                        className={props.className}
                        container-type={'parent-grid'}
                        href={props.href}
                        ButtonBaseContentComponent={() => <>
                            <CardProps.Reference />
                            {props.label}
                        </>}
                    />
                }
            </Suspense>
        </>
    )
}
