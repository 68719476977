import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'

import UAParser from 'ua-parser-js'
import clsx from 'clsx'

import getUtils from 'wapplr-react/dist/common/Wapp/getUtils'
import { WappContext } from 'wapplr-react/dist/common/Wapp'

import DashboardIcon from 'octopwn-ui/dist/common/src/svg/DashboardIcon'
import SettingsIcon from 'octopwn-ui/dist/common/src/svg/SettingsIcon'
import LogoutIcon from 'octopwn-ui/dist/common/src/svg/LogoutIcon'
import Paper from 'octopwn-ui/dist/common/src/components/Paper'

import { copyObject } from 'wapplr/dist/common/utils'

import '../Template/component.css'
import defaultStyle from './component.css'

import { runPostTypesConfigSync } from '../../postTypes'

import InitOctopwnPaymentsStates, {
    getOctopwnPaymentsFromCookieFunctions,
    updateCart,
    updateProducts
} from '../../postTypes/product/PriceByCurrency/InitOctopwnPaymentStates'

import messages from '../../config/constants/messages'
import labels from '../../config/constants/labels'
import titles from '../../config/constants/titles'
import routes from '../../config/constants/routes'
import menus from '../../config/constants/menus'

import Template from '../Template'
import Account from '../Account'
import Stripe from '../../postTypes/payment/Stripe'
import NotFound from '../NotFound'
//import Cookies from '../Cookies'
import Dialog from '../Dialog'

import AppContext from './context'

import { clear as localStorageClear, storage as localStorage } from './localStorage'
import { clear as memoStorageClear, storage as memoStorage } from './memoStorage'

function getUserMenu(props) {

    const { appContext, context, classNames } = props

    const utils = getUtils(context)

    const adminMenu = runPostTypesConfigSync({
        action: 'adminMenu',
        p: { ...props, appContext, utils }
    }).filter((m) => m)

    return [
        {
            label: appContext.menus.dashboardMenu,
            href: (p) => appContext.routes.userRoute + '/' + p.user?._id,
            role: (p) => p?.user?._id,
            startIcon: <DashboardIcon />
        },
        {
            label: appContext.menus.accountSettingsMenu,
            href: (p) => appContext.routes.userRoute + '/' + p.user?._id + '/profilesettings',
            role: (p) => p?.user?._id,
            startIcon: <SettingsIcon />
        },
        {
            label: appContext.menus.logoutMenu,
            onClick: async (e) => {
                if (e) {
                    e.preventDefault()
                }
                await utils.logout({
                    requestName: 'userLogout',
                    redirect: { pathname: appContext.routes.accountRoute + '/loggedout', search: '', hash: '' }
                })
            },
            role: (p) => p?.user?._id && !p.isMobileMenu,
            startIcon: <LogoutIcon />
        },
        {
            divider: true,
            order: 199,
            role: function(p) {
                return (p.user && p.user._status_isFeatured)
            }
        },
        {
            label: 'Admin',
            role: function(p) {
                return p.user && p.user._status_isFeatured
            },
            order: 200,
            MenuBaseProps: {
                PaperProps: {
                    className: classNames.adminPaper
                }
            },
            items: [
                ...adminMenu.reduce((a, menu) => {
                    if (Array.isArray(menu)) {
                        a.push(...menu)
                    } else {
                        a.push(menu)
                    }
                    return a
                }, [])
            ],
            menuType: 'list'
        }
    ]
}

function OfflineLayer(props) {

    const [show, setShow] = useState(props.show || false)

    useEffect(() => {
        if (props.effect) {
            props.effect({
                actions: {
                    setShow
                }
            })
        }
        return () => {
            if (props.effect) {
                props.effect({
                    actions: {
                        setShow: async () => null
                    }
                })
            }
        }
    })

    return (
        <div className={clsx(
            props.classNames.offlineLayer,
            { [props.classNames.offlineLayerShow]: show }
        )} />
    )
}

function NewReleasePopup(props) {

    const context = useContext(WappContext)
    const appContext = useContext(AppContext)

    const { wapp } = context
    const { effect } = props

    const dialog = useRef()

    useEffect(() => {
        if (effect) {
            effect({
                actions: {
                    open: () => {
                        dialog.actions?.open({
                            dialogTitle: appContext.titles.newReleaseWarningTitle,
                            dialogContent: appContext.messages.newReleaseWarningContent,
                            cancelText: appContext.labels.newReleaseCancelText,
                            submitText: appContext.labels.newReleaseSubmitText,
                            onSubmit: async () => {
                                await wapp.pwaUnregister()
                                window.location.reload()
                            }
                        })
                    }
                }
            })
        }
    })

    return (
        <Dialog
            effect={({ actions }) => {
                dialog.actions = actions
            }}
            ContentComponentProps={{
                Component: 'div'
            }}
        />
    )
}

export default function App(p = {}) {

    const context = useContext(WappContext)
    const { wapp, req, res } = context
    const utils = getUtils(context)

    const props = {
        ...p,
        ...res.wappResponse.content?.ComponentProps ? res.wappResponse.content.ComponentProps : {}
    }

    const {
        subscribe,
        parentRoute = '',
        userPostTypeName = 'user',
        fullPage,
        classNames = defaultStyle,
        TemplateProps = {},
        PostTypeComponentProps = {}
    } = props

    //wapp.styles.use(classNames)

    const {
        setOctopwnPaymentsToCookie,
        getOctopwnPaymentsFromCookie
    } = getOctopwnPaymentsFromCookieFunctions({ wapp, req, res })

    const paymentsFromCookie = getOctopwnPaymentsFromCookie()

    const [url, setUrl] = useState(utils.getRequestUrl())
    const [user, setUser] = useState(utils.getRequestUser())
    const [currency, setCurrency] = useState(paymentsFromCookie?.currency || res.wappResponse.store.getState('octopwnPayments.currency'))

    const template = useRef()
    const waitForRender = useRef(true)
    const buttonLastHrefPushToHistory = useRef({})
    const networkStateOffline = useRef(false)
    const offlineLayer = useRef()
    const newRelease = useRef()

    useEffect(() => {
        if (wapp.target === 'web' && wapp.client) {
            wapp.client.onlineHandler = () => {
                template.current?.actions?.setSnackMessage(appContext.messages.onlineAgain, 3000)
                template.current?.actions?.setLogoBlackAndWhite(false)
                networkStateOffline.current = false
                offlineLayer.actions.setShow(false)
                document.body.style.position = null
                document.body.style.overflow = null
                document.body.style.width = null
                document.body.style.height = null
                if (!shouldLeaveWarning.current) {
                    setTimeout(async () => {
                        await wapp.pwaUnregister()
                        window.location.reload()
                    })
                }
            }
            wapp.client.offlineHandler = () => {
                template.current?.actions?.setSnackMessage(appContext.messages.offlineWarning, 6000, 'error')
                template.current?.actions?.setLogoBlackAndWhite(true)
                networkStateOffline.current = true
                offlineLayer.actions.setShow(true)
                document.body.style.position = 'fixed'
                document.body.style.overflow = 'hidden'
                document.body.style.width = '100vw'
                document.body.style.height = '100vh'
            }
            wapp.client.serviceWorkerUpdated = () => {
                if (!networkStateOffline.current) {
                    newRelease.actions?.open()
                }
            }
            wapp.client.handleNotFoundResponse = () => {
                if (!networkStateOffline.current) {
                    newRelease.actions?.open()
                }
            }
        }
    }, [])

    const storageName = wapp.globals.NAME
    const storage = function(data, memo) {
        if (memo) {
            return memoStorage((data) ? data : {}, storageName)
        }
        return localStorage(data, storageName)
    }

    const storageClear = function(memo) {
        if (memo) {
            return memoStorageClear(storageName)
        }
        return localStorageClear(storageName)
    }

    function saveScrollTopAfterFinishedRender() {
        const urlKey = wapp.client.history.getState().key || 'initial'
        let scrollTop = storage(undefined, true)['scrollTop_' + urlKey] || 0
        storage({ ['shouldScrollTopAfterRender']: scrollTop }, true)
    }

    async function setScrollTop() {

        let scrollTop = storage(undefined, true)['shouldScrollTopAfterRender']
        let smooth = false

        if (scrollTop >= 0) {
            storage({ ['shouldScrollTopAfterRender']: -1 }, true)
            try {
                if (scrollTop === 0 && window.location.hash) {
                    const e = document.getElementById(window.location.hash.slice(1))
                    if (e) {

                        const maxScrollY = template.current?.actions?.getMaxScrollY() || 0
                        scrollTop = e.getBoundingClientRect().top + window.scrollY - 100 - 16

                        if (maxScrollY < scrollTop && maxScrollY) {
                            scrollTop = maxScrollY
                        }

                        smooth = true
                    }
                }
            } catch (e) {
            }

            if (template.current?.actions?.setScrollTop) {
                await template.current.actions.setScrollTop(scrollTop, smooth)
            }

        }
    }

    function createStates() {
        if (!wapp.states.stateManager.actions.app) {
            wapp.states.stateManager.actions.app = function({ type, name, value }) {
                return {
                    type: type || 'SET_APP',
                    payload: {
                        name,
                        value
                    }
                }
            }
            wapp.states.stateManager.reducers.app = function(state = {}, action) {
                switch (action.type) {
                    case 'SET_APP':
                        return {
                            ...state,
                            [action.payload.name]: (action.payload.value && typeof action.payload.value == 'object') ? copyObject(action.payload.value) : action.payload.value
                        }
                    default:
                        return state
                }
            }

            if (wapp.target === 'web') {
                wapp.client.history.globalHistory.scrollRestoration = 'manual'
            }

        }
    }

    function storeUrl(action, url, { key = 'initial' }) {

        const history = res.wappResponse.store.getState('app.history') || []

        const lastItem = history[history.length - 1]

        if (lastItem && lastItem.key !== key || !lastItem) {

            const current = {
                action: action || 'PUSH',
                url: url,
                key,
                referrer: lastItem?.url || ''
            }

            history.push(current)

            res.wappResponse.store.dispatch(wapp.states.runAction('app', { name: 'history', value: history }))
            res.wappResponse.store.dispatch(wapp.states.runAction('app', { name: 'current', value: current }))

        }

    }

    function subscribeAppStore() {

        createStates()

        const unsubscribeFromState = res.wappResponse.store.subscribe(function({ type, payload }) {
            if (wapp.target === 'web' && wapp.globals.DEV) {
                console.log('[APP] Change state:', type, payload)
            }
        })

        const unsubscribeHistoryListener = (wapp.target === 'web') ?
            wapp.client.history.addListener(function({ action, location, state }) {
                storeUrl(action, location.pathname + location.search + location.hash, state)
                if (action === 'POP') {
                    delete buttonLastHrefPushToHistory.current.time
                    delete buttonLastHrefPushToHistory.current.href
                }
            }) : null

        return () => {
            unsubscribeFromState()
            unsubscribeHistoryListener()
        }

    }

    /*todo: here, all user interactions, such as scroll and click, will have to be saved in the app state*/

    if (wapp.target === 'node') {
        createStates()
        storeUrl('PUSH', url, { key: 'initial' })
    }

    useEffect(() => {
        const unsubscribe = subscribeAppStore()
        const key = wapp.client.history.getState().key || 'initial'
        storeUrl('PUSH', url, { key })
        return unsubscribe
    }, [])

    if (wapp.target === 'web') {
        useLayoutEffect(() => {
            if (waitForRender.current) {
                waitForRender.current = false
                setScrollTop()
            }
        }, [url])
    }

    async function onLocationChange(newUrl) {
        if (template.current?.actions?.setLogoAnimation) {
            // noinspection ES6MissingAwait
            template.current.actions.setLogoAnimation(1)
        }
        saveScrollTopAfterFinishedRender()
        if (url !== newUrl) {
            waitForRender.current = true
            setUrl(newUrl)
        } else {
            await setScrollTop()
            if (template.current?.actions?.setLogoAnimation) {
                // noinspection ES6MissingAwait
                template.current.actions.setLogoAnimation(0)
            }
        }
    }

    async function onUserChange(newUser) {

        hb.current.run = false
        hb.current._id = newUser?._id || null

        const orderPostTypeName = 'order'

        if (!user?._id && newUser?._id) {

            function copyLocalStorage() {

                const storageName = user?._id ? orderPostTypeName + '_' + user._id : orderPostTypeName
                const storageData = storage()[storageName] || {}
                const cart = (storageData['record.cart']) ? JSON.parse(storageData['record.cart']) : []

                const newStorageName = newUser?._id ? orderPostTypeName + '_' + newUser._id : orderPostTypeName
                const newStorageData = storage()[storageName] || {}

                if (cart.length) {

                    storage({
                        [storageName]: {
                            ...storageData,
                            ['record.cart']: JSON.stringify([])
                        },
                        [newStorageName]: {
                            ...newStorageData,
                            ['record.cart']: JSON.stringify(cart)
                        }
                    })

                    return true

                }
            }

            function copyMemoStorage() {

                const storageName = user?._id ? orderPostTypeName + '_' + user._id : orderPostTypeName
                const storageData = storage(undefined, true)[storageName]

                const newStorageName = newUser?._id ? orderPostTypeName + '_' + newUser._id : orderPostTypeName
                const newStorageData = storage(undefined, true)[storageName]

                storage({
                    [storageName]: {},
                    [newStorageName]: {
                        ...newStorageData,
                        ...storageData
                    }
                }, true)
            }

            const updated = copyLocalStorage()
            if (updated) {
                copyMemoStorage()
            }

        } else if (user?._id && !newUser?._id) {
            const storageName = user?._id ? orderPostTypeName + '_' + user._id : orderPostTypeName
            storage({ [storageName]: {} }, true)
        }

        await setUser((newUser?._id) ? newUser : null)

        const shouldClearCache = (newUser?._id !== user?._id)

        if (shouldClearCache) {

            if (wapp.globals.DEV) {
                console.log('[APP] Clear cache')
            }

            if (!newUser?._id) {

                res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                    type: 'SET_RES',
                    name: 'cache',
                    value: {}
                }))

                res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                    type: 'SET_RES',
                    name: 'responses',
                    value: {}
                }))

                storageClear(true)
                storageClear()

                const urlKey = wapp.client.history.getState().key || 'initial'
                storage({ ['scrollTop_' + urlKey]: 0 }, true)
                storage({ ['shouldScrollTopAfterRender']: 0 }, true)

                wapp.pwaClearCaches()

            } else {

                res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                    type: 'SET_RES',
                    name: 'cache',
                    value: {}
                }))

            }

        }

        hb.current.run = true

    }

    /*useEffect(()=>{
        function onError(message, url, lineno) {
            if (message !== "ResizeObserver loop completed with undelivered notifications.") {
                alert("Error:\n\t" + message + "\nLine:\n\t" + lineno + "\nFile:\n\t" + url)
            }
        }
        window.onerror = onError
        return ()=>{
            window.onerror = null
        }
    })*/

    useEffect(function() {
        const unsub1 = subscribe.locationChange(onLocationChange)
        const unsub2 = subscribe.userChange(onUserChange)
        return function useUnsubscribe() {
            unsub1()
            unsub2()
        }
    }, [subscribe, url, user])

    useEffect(() => {
        if (template.current?.actions?.setLogoAnimation) {
            const history = res.wappResponse.store.getState('app.history') || []
            const lastItem = history[history.length - 1]
            if (lastItem.key !== 'initial' && history.length > 1) {
                template.current.actions.setLogoAnimation(0)
            }
        }
    })

    useEffect(() => {
        if (wapp.target === 'web') {
            const cssAssets = res.wappResponse.store.getState('res.assets.css')
            const themeCss = cssAssets.find((css) => css.match('theme'))

            if (themeCss) {

                const criticalCss = cssAssets.find((css) => css.match('critical'))

                function getReference() {
                    const globals = wapp.globals
                    const { WAPP } = globals
                    const criticalStyle = document.getElementById('css_' + WAPP)
                    const criticalLink = (criticalCss) ? document.getElementById(criticalCss) : null
                    return criticalLink || criticalStyle
                }

                async function success() {
                    console.log('[APP] Full theme loaded...')
                    await new Promise((resolve) => setTimeout(resolve, 1500))
                    const reference = getReference()
                    if (reference) {
                        const head = document.getElementsByTagName('head')[0]
                        head.removeChild(reference)
                    }
                }

                async function createLink() {

                    const head = document.getElementsByTagName('head')[0]
                    const link = document.createElement('link')
                    link.rel = 'stylesheet'
                    link.type = 'text/css'
                    link.href = themeCss
                    link.id = themeCss
                    link.onload = async () => {
                        success()
                    }

                    const reference = getReference()

                    head.insertBefore(link, reference ? reference.nextSibling : null)

                }

                console.log('[APP] Hydrated')

                if (!document.getElementById(themeCss)) {
                    function add() {
                        if (!document.getElementById(themeCss)) {
                            if (window.requestIdleCallback) {
                                window.requestIdleCallback(createLink)
                            } else {
                                createLink()
                            }
                        }
                    }

                    function on(e) {
                        if (e.isTrusted) {
                            add()
                            removeListeners()
                        }
                    }

                    function removeListeners() {
                        window.removeEventListener('touchstart', on)
                        window.removeEventListener('mousemove', on)
                    }

                    window.addEventListener('touchstart', on)
                    window.addEventListener('mousemove', on)

                    return () => {
                        removeListeners()
                    }

                }

            }
        }
    }, [])

    const hb = useRef({ run: true, _id: user?._id || null })

    async function checkUser(instance) {
        await new Promise((resolve) => setTimeout(resolve, 5000))
        hb.current.wasInteraction = false
        await new Promise((resolve) => {
            if (hb.current.interval) {
                clearInterval(hb.current.interval)
            }
            if (hb.current.timeout) {
                clearTimeout(hb.current.timeout)
            }
            hb.current.interval = setInterval(() => {
                if (hb.current.wasInteraction) {
                    if (hb.current.interval) {
                        clearInterval(hb.current.interval)
                    }
                    if (hb.current.timeout) {
                        clearTimeout(hb.current.timeout)
                    }
                    resolve()
                }
            }, 100)
            hb.current.timeout = setTimeout(() => {
                if (hb.current.interval) {
                    clearInterval(hb.current.interval)
                }
                if (hb.current.timeout) {
                    clearTimeout(hb.current.timeout)
                }
                resolve()
            }, 1000 * 60 * 10)
        })
        let response = !networkStateOffline.current ? await wapp.requests.send({
            requestName: 'userHb',
            args: { _id: hb.current._id },
            req,
            res
        }) : null
        if (response && response['userHb']) {
            response = response['userHb']
        }
        if (!response?.sa && !networkStateOffline.current) {
            console.log('[APP] Need update user')
            if (instance.update) {
                await instance.update(response)
            }
        }
        if (instance.run && hb.current.run) {
            await checkUser(instance)
        }
    }

    function addCheckUserInteractionListener() {

        if (hb.current.removeListeners) {
            hb.current.removeListeners()
        }

        function on(e) {
            if (e.isTrusted && hb.current.run) {
                hb.current.wasInteraction = true
            }
        }

        hb.current.removeListeners = function() {
            window.removeEventListener('touchstart', on)
            window.removeEventListener('mousemove', on)
            hb.current.removeListeners = null
        }

        window.addEventListener('touchstart', on)
        window.addEventListener('mousemove', on)

        return hb.current.removeListeners

    }

    function syncRunCheckUser() {
        const instance = {
            run: true,
            update: async (/*p*/) => {
                //const _id = p?.next || null
                if (!networkStateOffline.current) {
                    window.location.href = '/'
                }
            }
        }
        addCheckUserInteractionListener()
        checkUser(instance)
        return () => {
            instance.run = false
            instance.update = null
            if (hb.current.removeListeners) {
                hb.current.removeListeners()
            }
        }
    }

    useEffect(() => {
        return syncRunCheckUser()
    }, [user?._id])

    const userStatusManager = wapp.getTargetObject().postTypes.findPostType({ name: userPostTypeName }).statusManager

    const shouldLeaveWarning = useRef(false)
    const dialog = useRef()

    const { device } = new UAParser(req.wappRequest.userAgent).getResult()

    const appContext = {
        messages,
        labels,
        titles,
        routes,
        menus,
        userStatusManager,
        userPostTypeName,
        storage,
        template,
        account: {
            termsSlug: 'eula',
            cookiesSlug: 'cookie-policy',
            privacySlug: 'privacy-policy'
        },
        setShouldLeaveWarning: (value) => {
            shouldLeaveWarning.current = value
        },
        getShouldLeaveWarning: () => shouldLeaveWarning.current,
        device
    }

    const PostTypesComponent = runPostTypesConfigSync({
        action: 'getComponent',
        p: { context, appContext }
    }).filter((C) => !!(C))[0]

    const route = res.wappResponse.route
    const requestPath = route.requestPath

    const history = res.wappResponse.store.getState('app.history') || []
    const lastItem = history[history.length - 1]
    const probablyWaitingForLogout = (
        lastItem?.url?.startsWith(appContext.routes.accountRoute + '/login') ||
        lastItem?.url?.startsWith(appContext.routes.accountRoute + '/loggedout')
    )

    return (
        <AppContext.Provider value={appContext}>
            <Template
                {...TemplateProps}
                menu={[
                    {
                        label: 'Features & Pricing',
                        href: '/features-and-pricing'
                    },
                    {
                        label: 'About Us',
                        href: '/about-us'
                    },
                    {
                        label: 'Support',
                        href: '/support'
                    }
                ]}
                userMenu={getUserMenu({ appContext, context, classNames })}
                featuredButtons={[
                    /*{
                        label: 'Get in touch',
                        href: '/#contact',
                        color: 'primary'
                    },*/
                    {
                        label: 'Request a demo',
                        href: '/enterprise-plan',
                        color: 'primary',
                        className: classNames.requestDemoButton
                    },
                    {
                        Component: ()=>{
                            return (
                                <div
                                    className={classNames.verticalDivider}
                                >
                                    <Paper
                                        size={'none'}
                                        variant={'contained'}
                                        color={'tertiary'}
                                    />
                                </div>
                            )
                        },
                        role: (p = {}) => {
                            const {
                                isMobileMenu,
                            } = p
                            return !isMobileMenu
                        }
                    },
                    {
                        label: appContext.menus.signupMenu,
                        href: appContext.routes.accountRoute + '/signup' + (req.wappRequest.query.redirect ? '?redirect=' + encodeURIComponent(req.wappRequest.query.redirect) : ''),
                        color: 'primary',
                        role: (p) => !p.user?._id
                    },
                    {
                        label: appContext.menus.loginMenu,
                        href: appContext.routes.accountRoute + '/login' + (req.wappRequest.query.redirect ? '?redirect=' + encodeURIComponent(req.wappRequest.query.redirect) : ''),
                        color: 'primary',
                        variant: 'outlined',
                        role: (p) => !p.user?._id
                    },
                    {
                        label: appContext.menus.logoutMenu,
                        //href: appContext.routes.accountRoute + '/logout',
                        onClick: async (e) => {
                            if (e) {
                                e.preventDefault()
                            }
                            await utils.logout({
                                requestName: 'userLogout',
                                redirect: {
                                    pathname: appContext.routes.accountRoute + '/loggedout',
                                    search: '',
                                    hash: ''
                                }
                            })
                        },
                        color: 'tertiary',
                        role: (p = {}) => {
                            const {
                                isMobileMenu,
                                user
                            } = p
                            return isMobileMenu && user?._id
                        }
                    }
                ]}
                handlers={{
                    onScroll: (e) => {
                        if (!waitForRender.current) {
                            if (url === res.wappResponse.store.getState('app.current.url')) {
                                const urlKey = wapp.client.history.getState().key || 'initial'
                                const scrollTop = e.target === document ? window.scrollY : e.target.scrollTop
                                storage({ ['scrollTop_' + urlKey]: scrollTop }, true)
                            }
                        }
                    }
                }}
                effect={({ actions }) => {
                    template.current = {
                        actions
                    }
                }}
                ButtonContextValue={{
                    href: ({ href }) => {

                        if (!href) {
                            return href
                        }

                        let internal = false
                        try {
                            const url = new URL(href, window.location.origin)
                            internal = (url && url.origin === window.location.origin && url.href.startsWith(window.location.origin))
                        } catch (e) {

                        }

                        if (internal) {
                            return href ? parentRoute + href : href
                        }

                        return href
                    },
                    onClick: async function(e, { href, target, disabled }) {

                        if (disabled) {
                            e.preventDefault()
                            e.stopPropagation()
                            return
                        }

                        if (!href || target === '_blank') {

                        } else {

                            try {
                                const hrefUrl = new URL(href, window.location.origin)
                                if (hrefUrl && hrefUrl.origin === window.location.origin && hrefUrl.href.startsWith(window.location.origin)) {

                                    e.preventDefault()
                                    e.stopPropagation()

                                    if (networkStateOffline.current) {
                                        appContext.template.current?.actions?.setSnackMessage(appContext.messages.offlineWarning, 6000, 'error')
                                        return
                                    }

                                    if (shouldLeaveWarning.current) {

                                        dialog.actions.open({
                                            dialogTitle: appContext.titles.leaveWarningTitle,
                                            dialogContent: appContext.messages.leaveWarningMessage,
                                            cancelText: appContext.labels.leaveWarningCancel,
                                            submitText: appContext.labels.leaveWarningSubmit,
                                            onSubmit: async function() {

                                                dialog.actions.close()
                                                shouldLeaveWarning.current = false

                                                if (template.current?.actions?.setLogoAnimation) {
                                                    // noinspection ES6MissingAwait
                                                    template.current.actions.setLogoAnimation(1)
                                                }

                                                wapp.client.history.push({
                                                    search: '',
                                                    hash: '',
                                                    ...wapp.client.history.parsePath(hrefUrl.href.split(window.location.origin)[1])
                                                })
                                            }
                                        })

                                    } else {

                                        let isSamePage = false
                                        try {
                                            const absoluteUrl = new URL(url, window.location.origin)
                                            if (absoluteUrl.href === hrefUrl.href) {
                                                isSamePage = true
                                            }
                                        } catch (e) {

                                        }

                                        const wait = 1000 * (isSamePage ? 5 : 10)
                                        const now = Date.now()

                                        if (buttonLastHrefPushToHistory.current.href === hrefUrl.href && buttonLastHrefPushToHistory.current.time + wait > now) {
                                            console.log('[APP] Prevent click again... wait ' + (buttonLastHrefPushToHistory.current.time + wait - now) + ' ms')
                                            template.current.actions.setLogoAnimation(0)
                                            appContext.template.current?.actions?.setSnackMessage((isSamePage) ? appContext.messages.preventClickDone : appContext.messages.preventClickAgain)
                                            return
                                        }

                                        buttonLastHrefPushToHistory.current.time = Date.now()
                                        buttonLastHrefPushToHistory.current.href = hrefUrl.href

                                        if (template.current?.actions?.setLogoAnimation) {
                                            // noinspection ES6MissingAwait
                                            template.current.actions.setLogoAnimation(1)
                                        }

                                        wapp.client.history.push({
                                            search: '',
                                            hash: '',
                                            ...wapp.client.history.parsePath(hrefUrl.href.split(window.location.origin)[1])
                                        })

                                    }

                                    //return;
                                }
                            } catch (e) {
                            }
                        }
                    }
                }}
                MenuContextValue={{
                    isActive: (props) => {

                        if (typeof props.active == 'boolean') {
                            return props.active
                        }

                        let href = props.href ? parentRoute + props.href : props.href || ''

                        if (!href) {
                            return false
                        }

                        const hrefWithoutSearch = href.split('?')[0]
                        const urlWithoutSearch = url.split('?')[0]

                        const isListPage = (url?.match('/page/') && !url?.endsWith('/page')) || url?.match('/limit') || url?.match('/sort') || url?.match(/\?search=/)

                        const starsWidthAndNext =
                            (url.startsWith(href + '/page') && !url.startsWith(href + '/pages')) ||
                            (url.startsWith(href + '/limit')) ||
                            (url.startsWith(href + '/sort')) ||
                            (url.startsWith(href + '\?search='))

                        return isListPage ? (starsWidthAndNext || hrefWithoutSearch.startsWith(urlWithoutSearch)) : (href === url)
                    },
                    getPropsForMenuItemProps: () => {
                        return {
                            user
                        }
                    }
                }}
                MenuProps={{
                    storageOpenMenu: storage,
                    menuKey: 'octopwnMainMenu'
                }}
                UserMenuDesktopProps={{
                    storageOpenMenu: storage,
                    storageUserMenuScrollTop: storage,
                    menuKey: 'octopwnUserMenu'
                }}
                MobileMenuProps={{
                    storageDrawerScrollTop: storage
                }}
                ThemeControlsProps={{
                    storageOpenMenu: storage
                }}
                FooterProps={{
                    footerSubtitle: 'Made with love in Switzerland. Hack safely, always use Octopwn.',
                    footerColTitles: ['SITEMAP', 'LEGAL', 'GET STARTED'],
                    footerChildren: [
                        {
                            menu: [
                                /*{
                                    label: 'Solutions',
                                    href: '/'
                                },*/
                                {
                                    label: 'Features & Pricing',
                                    href: '/features-and-pricing'
                                },
                                {
                                    label: 'About Us',
                                    href: '/about-us'
                                },
                                {
                                    label: 'Support',
                                    href: '/support'
                                },
                                {
                                    label: 'Documentation',
                                    href: 'https://docs.octopwn.com',
                                    target: '_blank'
                                }
                            ],
                            MenuItemProps: {
                                size: 'large'
                            }
                        },
                        {
                            menu: [
                                {
                                    label: 'End User Licence Agreement (EULA)',
                                    href: '/eula'
                                },
                                {
                                    label: 'Privacy Policy',
                                    href: '/privacy-policy'
                                },
                                {
                                    label: 'Cookie Policy',
                                    href: '/cookie-policy'
                                }
                            ],
                            MenuItemProps: {
                                size: 'large'
                            }
                        },
                        {
                            menu: [
                                {
                                    label: appContext.menus.signupMenu,
                                    href: appContext.routes.accountRoute + '/signup',
                                    role: (p) => !p.user?._id
                                },
                                {
                                    label: appContext.menus.loginMenu,
                                    href: appContext.routes.accountRoute + '/login' + (req.wappRequest.query.redirect ? '?redirect=' + encodeURIComponent(req.wappRequest.query.redirect) : ''),
                                    role: (p) => !p.user?._id
                                },
                                {
                                    label: 'Try the community edition',
                                    href: 'https://live.octopwn.com',
                                    target: '_blank'
                                },
                                {
                                    label: 'Contact us',
                                    href: '/contact-us'
                                }
                            ],
                            MenuItemProps: {
                                size: 'large'
                            }
                        }
                    ],
                    footerSocialMenu: [],
                    footerCopyright: 'All rights reserved. - Octopwn - ' + new Date().getFullYear(),
                    footerPaymentsImage: null
                }}
                fullPage={fullPage}
            >
                <InitOctopwnPaymentsStates
                    key={'currency-' + currency}
                    handlers={{
                        changeCurrency: async (payload, nextState) => {
                            if (nextState?.currency && nextState.currency !== currency) {
                                if (wapp.target === 'web') {
                                    console.log('[APP] Change currency:', payload, nextState)
                                    setOctopwnPaymentsToCookie()
                                }
                                await updateProducts({ context })
                                await updateCart({ context, appContext })
                                await setCurrency(nextState.currency)
                            }
                        }
                    }}
                />
                {
                    (requestPath.startsWith(routes.accountRoute)) ?
                        <Account />
                        :
                        (requestPath.startsWith(routes.payRoute)) ?
                            <Stripe /> :
                            (PostTypesComponent) ?
                                <PostTypesComponent {...PostTypeComponentProps} />
                                :
                                <>
                                    {!probablyWaitingForLogout ? <NotFound /> : null}
                                </>
                }
                {/*<Cookies />*/}
                <Dialog
                    effect={({ actions }) => {
                        dialog.actions = actions
                    }}
                    ContentComponentProps={{
                        Component: 'div'
                    }}
                />
                <NewReleasePopup
                    effect={({ actions }) => {
                        newRelease.actions = actions
                    }}
                />
            </Template>
            <OfflineLayer
                effect={({ actions }) => {
                    offlineLayer.actions = actions
                }}
                classNames={classNames}
                show={networkStateOffline.current}
            />
        </AppContext.Provider>
    )
}
