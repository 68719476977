import React, { useContext } from 'react'

import getUtils from 'wapplr-react/dist/common/Wapp/getUtils'
import { WappContext } from 'wapplr-react/dist/common/Wapp'

import capitalize from '../../utils/capitalize'

import App from '../../components/App'
import { CustomCard } from '../../components/Posts/Components/CustomCard'

import { getDefaultContent, getListContent } from '../post/contents'

import defaultPostTypeConfig from '../post'
import { runPostTypesConfig } from '../index'

import { transformPosts } from './Products/transformPosts'
import productsClassNames from './Products/styleSync.css'

import Content from './Content'
//import ListHeader from './ListHeader'
import Overview from './Overview'

import getConstants from './constants'

const postTypeConfig = {
    getStatusManager: function getStatusManager(p = {}) {
        return defaultPostTypeConfig.getStatusManager({
            ...p,
            config: {
                requiredDataForStatus: {
                    title: { type: String }
                },
                ...(p.config) ? p.config : {}
            }
        })
    },
    getConstants: getConstants,
    setContents: function setContents(p = {}) {

        const {
            wapp,
            name = 'product',
            routes,
            urlParams = [
                '/page/:pagination',
                '/sort/:sort',
                '/limit/:limit',
                '/categories/:categories'
            ],
            ...rest
        } = p

        const n = name
        const N = capitalize(n)
        const ns = (n.endsWith('y')) ? n.slice(0, -1) + 'ies' : n + 's'

        const r = defaultPostTypeConfig.setContents({
            ...p,
            name,
            urlParams
        })

        let reqUserForPost = null

        const slugPattern = /^[a-z\d](-?[a-z\d]){1,150}$/
        const objectIdPattern = /^[\da-fA-F]{24}$/
        const eanPattern = /^(\d{13})?$/

        const contentProps = wapp.contents.get(name)

        wapp.contents.handle = async function defaultHandle(req, res, next) {
            if (!res.wappResponse.content) {
                const contentRes = wapp.contents.contentManager.resolve
                const content = await contentRes({ route: res.wappResponse.route, wapp, req, res })
                if (!res.wappResponse.content) {
                    res.wappResponse.content = content
                }
            }
            return next()
        }

        function getPost(res) {
            const wappResponse = res.wappResponse
            const route = wappResponse.route
            const { params } = route
            const { _id } = params
            const isEanRequest = !_id?.match(objectIdPattern) && _id?.match(eanPattern)
            const isSlugRequest = !_id?.match(objectIdPattern) && !isEanRequest && _id?.match(slugPattern)

            const posts = [
                wappResponse.store.getState('res.responses.' + name + 'FindById'),
                wappResponse.store.getState('res.responses.' + name + 'FindBySlug'),
                wappResponse.store.getState('res.responses.' + name + 'FindByEan')
            ]

            return posts.find((p) => isSlugRequest ? p?.slug === _id : isEanRequest ? p?.ean === _id : p?._id === _id)
        }

        const listContent = getListContent({ wapp, name, routes, urlParams, App, ...rest })
        const postContent = getDefaultContent({ wapp, name, routes, urlParams, App, ...rest })

        function _transformRequestParams({ requestParams }) {
            return requestParams
        }

        async function _request(props) {

            const {
                wapp,
                req,
                res,
                disableCache,
                transformRequestParams = _transformRequestParams,
                params = res.wappResponse.route.params,
                query = req.wappRequest.query,
                url = req.url
            } = props

            const wappResponse = res.wappResponse

            const postStatusManager = wapp.getTargetObject().postTypes.findPostType({ name }).statusManager
            const userStatusManager = wapp.getTargetObject().postTypes.findPostType({ name: 'user' }).statusManager

            const listData = wappResponse.store.getState('res.graphql.query.' + name + 'FindMany.listData') || {}
            const listDataSort = listData.sort || []

            /*

              word search for ads:

                - accented characters and capital letters do not count
                - it only searches for words, not word parts, there is an OR relationship between the words
                - the most important in the row arrangement is [category], then comes the title and content

            * */

            if (query.search) {
                listDataSort.push({ key: 'TEXTSCORE' })
            }

            const defaultSort = listDataSort[0]?.key || ''

            let sort = (params.sort && listDataSort && listDataSort.map((p) => p.key).find((key) => key === params.sort)) || defaultSort

            if (sort === 'TEXTSCORE') {
                sort = ''
            }

            const page = params.page
            const pagination = !isNaN(Number(params.pagination)) && Number(params.pagination) ? Number(params.pagination) : 1

            const perPageFormData = listData.perPage
            const limitPerPage = perPageFormData.limit || 100
            const defaultPerPage = perPageFormData.default || 20
            const perPage = (params.limit && !isNaN(Number(params.limit)) && Number(params.limit) <= limitPerPage && Number(params.limit) > 1) ? Number(params.limit) : defaultPerPage

            const formData = wappResponse.store.getState('res.graphql.query.' + name + 'FindMany.formData')

            const filter = urlParams.reduce((o, urlParamText) => {
                if (!urlParamText.startsWith('/page/:') && !urlParamText.startsWith('/sort/:') && !urlParamText.startsWith('/limit/:')) {
                    const paramNameN = urlParamText.startsWith('/') ? 1 : 0
                    const paramName = urlParamText.split('/')[paramNameN]
                    if (params[paramName] && formData['filter.' + paramName]) {
                        const { schemaType, multiple } = formData['filter.' + paramName]
                        let value = params[paramName]
                        if (multiple) {
                            try {
                                value = (value.startsWith('[') && value.endsWith(']')) ? JSON.parse(value) : value.split(',')
                            } catch (e) {
                            }
                        } else {
                            if (schemaType === 'Float' || schemaType === 'Int') {
                                value = Number(value)
                            }
                        }
                        if (typeof value !== 'undefined') {
                            o[paramName] = value
                        }
                    }
                }
                return o
            }, {})

            const authorStatus =
                (page === 'lowauthorstatus') ?
                    {
                        _author_status: {
                            lt: userStatusManager.getMinStatus()
                        }
                    } :
                    (page === 'banned' || page === 'deleted' || page === 'missingdata' || page === 'protected') ? {
                            _author_status: {
                                gt: userStatusManager.getBannedStatus() - 1
                            }
                        } :
                        {
                            _author_status: {
                                gt: userStatusManager.getMinStatus() - 1
                            }
                        }

            const status =
                (page === 'banned') ?
                    { gt: postStatusManager.getBannedStatus() - 1, lt: postStatusManager.getDeletedStatus() } :
                    (page === 'deleted') ?
                        { gt: postStatusManager.getDeletedStatus() - 1, lt: postStatusManager.getDefaultStatus() } :
                        (page === 'missingdata') ?
                            { gt: postStatusManager.getDefaultStatus() - 1, lt: postStatusManager.getMinStatus() } :
                            (page === 'protected') ?
                                { gt: postStatusManager.getFeaturedStatus() - 1 } :
                                (page === 'lowauthorstatus') ?
                                    { gt: postStatusManager.getBannedStatus() - 1 } :
                                    { gt: postStatusManager.getMinStatus() - 1 }

            const requestParams = transformRequestParams({
                requestParams: {
                    requestName: name + 'FindMany',
                    args: {
                        filter: {
                            ...(query.search) ? { search: query.search } : {},
                            ...(Object.keys(filter).length) ? filter : {},
                            _operators: {
                                _status: status,
                                ...authorStatus
                            }
                        },
                        ...(sort) ? { sort } : {},
                        page: pagination,
                        perPage
                    }
                }
            })

            const storageKey = name + '-posts-' + url + '-' + JSON.stringify(requestParams)

            if (wapp.target === 'web' && req.history?.action === 'POP' && !disableCache) {
                const response = res.wappResponse.store.getState('res.cache.' + storageKey)
                if (response) {
                    res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                        type: 'INS_RES',
                        name: 'responses',
                        value: { [requestParams.requestName]: response }
                    }))
                    return {
                        [requestParams.requestName]: response
                    }
                }
            }

            const response = await wapp.requests.send({ ...requestParams, req, res })

            if (wapp.target === 'web' && response && response[requestParams.requestName]) {
                res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                    type: 'INS_RES',
                    name: 'cache',
                    value: { [storageKey]: response[requestParams.requestName] }
                }))
            }

            return response

        }

        wapp.contents.add({
            [n]: {
                ...postContent,
                title: (context) => {

                    const { res } = context

                    const post = getPost(res)

                    let title = contentProps.title(context)

                    if (post?._id) {
                        title = post.title + ' | ' + title.split(' | ')[1]
                    }

                    return title
                },
                description: (context) => {

                    const { res } = context

                    const post = getPost(res)

                    let description = contentProps.description(context)

                    if (post?._id) {
                        description = post.title + ' ' + post.content_extract + ' | ' + description
                    }

                    return description
                },
                image: (context) => {

                    const { res } = context

                    const post = getPost(res)
                    let thumb = (post?._id && post.thumb) ? post.thumb : null

                    if (thumb && thumb.startsWith('/thumb')) {
                        thumb = thumb.replace('/thumb', '/thumb/2')
                    }

                    if (thumb) {

                        try {

                            const { req } = context
                            const hostname = req.wappRequest.hostname
                            const protocol = req.wappRequest.protocol
                            const url = new URL(protocol + '://' + hostname + thumb)
                            if (url.searchParams.get('crop')) {

                                const crop = JSON.parse(url.searchParams.get('crop'))
                                const width = crop.croppedAreaPixels.width
                                const height = crop.croppedAreaPixels.height
                                const maxDim = 800
                                const scale = Math.min(maxDim / width, maxDim / height)

                                return {
                                    width: Math.round(width * scale),
                                    height: Math.round(height * scale),
                                    thumb
                                }
                            }

                        } catch (e) {

                        }

                    }

                    return thumb
                },
                imageAlt: (context) => {

                    const { res } = context

                    const post = getPost(res)

                    return post?.title || ''
                },
                type: () => {
                    return 'og:product'
                },
                price: (context) => {

                    const { res } = context

                    const post = getPost(res)

                    return post?.price ? post.price : 0
                },
                currency: (context) => {
                    const { res } = context
                    const post = getPost(res)
                    return post?.currencyText || post?.currency || '€'
                },
                ldJson: (context) => {

                    const { res, req } = context
                    const post = getPost(res)

                    if (post?._id) {

                        const date = new Date()

                        date.setDate(date.getDate() + 30)

                        return JSON.stringify({
                            '@context': 'https://schema.org/',
                            '@type': 'Product',
                            'name': post.title,
                            'image': [
                                ...post.gallery ? post.gallery.map((image) => image.thumb) : (post.cover?.thumb) ? [post.cover?.thumb] : post.thumb ? [post.thumb] : []
                            ].map((url) => {
                                return req.wappRequest.protocol + '://' + req.wappRequest.hostname + url
                            }),
                            'description': post.content,
                            'sku': post.ean || post.slug,
                            'brand': {
                                '@type': 'Brand',
                                'name': 'OctoPwn'
                            },
                            'offers': {
                                '@type': 'Offer',
                                'url': req.wappRequest.protocol + '://' + req.wappRequest.hostname + '/product/' + (post.slug || post._id),
                                'priceCurrency': post.currencyText || post.currency || '€',
                                'price': post.price,
                                'priceValidUntil': date.toISOString().split('T')[0],
                                'itemCondition': 'https://schema.org/NewCondition',
                                'availability': post.available ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock'
                            }
                        })

                    }

                    return null
                },
                request: async function({ wapp, req, res }) {

                    const wappResponse = res.wappResponse
                    const route = wappResponse.route
                    const { params } = route
                    const { _id } = params
                    const isEanRequest = !_id?.match(objectIdPattern) && _id?.match(eanPattern)
                    const isSlugRequest = !_id?.match(objectIdPattern) && !isEanRequest && _id?.match(slugPattern)
                    const requestName = _id?.match(objectIdPattern) ? name + 'FindById' : isSlugRequest ? name + 'FindBySlug' : isEanRequest ? name + 'FindByEan' : null

                    const statusManager = wapp.getTargetObject().postTypes.findPostType({ name }).statusManager
                    await runPostTypesConfig({
                        action: 'requestFor' + N + 'Page',
                        p: { wapp, req, res, statusManager }
                    })

                    if (requestName) {

                        const post = getPost(res)

                        const args = isSlugRequest ? { slug: _id } : isEanRequest ? { ean: _id } : { _id }

                        if (
                            (reqUserForPost?._id !== req.wappRequest.user?._id) ||
                            (_id && !post) ||

                            (!isSlugRequest && !isEanRequest && post?._id !== _id) ||
                            (isSlugRequest && post?.slug !== _id) ||
                            (isEanRequest && post?.ean !== _id)
                        ) {
                            reqUserForPost = { _id: req.wappRequest.user?._id }
                            return await wapp.requests.send({ requestName, args, req, res })

                        } else {
                            return {
                                [requestName]: post
                            }
                        }

                    }
                }
            },
            [ns]: {
                ...listContent,
                ComponentProps: {
                    /*TemplateProps: {
                        BeforeChildren: ListHeader,
                        BeforeChildrenProps: {
                            parentRoute: ({ res }) => {

                                const route = res.wappResponse.route
                                const { params } = route
                                const { page } = params

                                return routes[ns + 'Route'] + (page ? '/' + page : '')
                            },
                            defaultTitle: ({ wapp, req, res }) => {
                                const title = typeof listContent.title === 'function' ? listContent.title({
                                    wapp,
                                    req,
                                    res
                                }) : listContent.title
                                return title ? title.split(' | ')[0] : undefined
                            }
                        }
                    }*/
                },
                title: (context) => {
                    const defaultTitleFunction = listContent.title
                    return defaultTitleFunction(context)
                },
                image: () => {
                    return undefined
                },
                imageAlt: () => {
                    return undefined
                },
                request: async function(props) {
                    return await _request(props)
                }
            }
        })

        return r
    },
    requestForUserPage: async function requestForUserPage(p = {}) {
        return await defaultPostTypeConfig.requestForUserPage({
            ...p,
            name: 'product'
        })
    },
    getComponent: function(p) {

        const name = 'product'
        const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'

        const { context, appContext } = p
        const { routes /*userStatusManager*/ } = appContext

        const { res, req } = context
        const route = res.wappResponse.route
        const requestPath = route.requestPath
        const user = req.wappRequest.user

        const isAdmin = user && user._status_isFeatured

        if (
            (requestPath.startsWith(routes[name + 'Route'])) ||
            (requestPath.startsWith(routes[ns + 'Route']))
        ) {

            const pages = {
                content: Content
            }

            if (requestPath === routes[name + 'Route'] + '/new' && !isAdmin && user) {
                return defaultPostTypeConfig.getComponent({
                    ...p,
                    name,
                    componentProps: { pages, getPageName: () => 'notFound' }
                })
            }

            if (requestPath.startsWith(routes[ns + 'Route']) && !isAdmin) {
                //return null
            }

            const requestKeys = [name + 'FindById', name + 'FindBySlug']
            const slugPattern = /^[a-z\d](-?[a-z\d]){1,150}$/
            const objectIdPattern = /^[\da-fA-F]{24}$/

            const utils = getUtils(context)
            const listDataObject = utils.getGlobalState('res.graphql.query.' + name + 'FindMany.listData')
            const listData = listDataObject.list
            const tableData = listDataObject.table

            listData.subtitle = {
                show: true
            }

            listData.contentExtract = {
                show: true
            }

            return defaultPostTypeConfig.getComponent({
                ...p,
                name,
                archiveComponentProps: {
                    selectable: false,
                    disablePageInfo: true,
                    accessForEditor: true,
                    beforeSetPosts: ({ posts }) => {
                        return transformPosts({
                            posts,
                            classNames: productsClassNames,
                            appContext,
                            context,
                            editable: true
                        })
                    },
                    tableData,
                    listData,
                    className: productsClassNames.postsComponent,
                    BeforePostsComponent: () => {
                        const context = useContext(WappContext)
                        const { wapp } = context
                        wapp.styles.use(productsClassNames)
                        return null
                    },
                    MenuItemProps: {
                        ContentPaperComponent: ({ children, className }) => <div className={className}>{children}</div>,
                        ContentExtractComponent: ({ children, className }) => <div
                            className={className}>{children}</div>
                    },
                    tableProps: {
                        showLabelTitle: true,
                        LabelBoxProps: {
                            titleAndLabelInline: true,
                            className: productsClassNames.labelBox
                        }
                    },
                    MenuItemComponent: (props) => {
                        return <CustomCard
                            {...props}
                            {...props.itemProps.post.paperColor ? {
                                color: props.itemProps.post.paperColor,
                                blur: true,
                                variant: 'contained'
                            } : {}}
                        />
                    }
                },
                componentProps: {
                    pages,
                    showAuthor: ({ user }) => user?._status_isFeatured,
                    //showCreatedDate: ({ user, author }) => user?._id && user._id === author?._id,
                    getProps: ({ pageName }) => {

                        if (pageName === 'content') {

                            return {
                                showAuthor: () => false,
                                PaperComponentProps: {
                                    color: undefined,
                                    elevation: 0,
                                    style: {
                                        padding: 0
                                    }
                                },
                                TitleComponentProps: {
                                    variant: 'h1'
                                }
                            }

                        }

                        return {}
                    },
                    requestKeys,
                    getInitialResponse: function() {

                        const wappResponse = res.wappResponse
                        const route = wappResponse.route
                        const { params } = route
                        const { _id } = params

                        const isSlugRequest = !_id?.match(objectIdPattern) && _id?.match(slugPattern)
                        const requestName = _id?.match(objectIdPattern) ? name + 'FindById' : isSlugRequest ? name + 'FindBySlug' : null

                        if (requestName) {
                            const posts = [wappResponse.store.getState('res.responses.' + name + 'FindById'), wappResponse.store.getState('res.responses.' + name + 'FindBySlug')]
                            return posts.find((p) => isSlugRequest ? p?.slug === _id : p?._id === _id)
                        }

                        return null

                    }
                }
            })
        }
    },
    userPageFunctions: function(p) {

        const { context, postContext } = p

        const n = 'product'
        const ns = (n.endsWith('y')) ? n.slice(0, -1) + 'ies' : n + 's'
        const N = capitalize(n)
        const Ns = capitalize(ns)

        const r = defaultPostTypeConfig.userPageFunctions({
            ...p,
            name: n,
            nsPageProps: {
                selectable: false,
                type: 'list',
                disablePageInfo: true,
                MenuItemComponent: (props) => {
                    return <CustomCard
                        {...props}
                        {...props.itemProps.post.paperColor ? {
                            color: props.itemProps.post.paperColor,
                            blur: true
                        } : {}}
                    />
                },
                ...p.nsPageProps ? p.nsPageProps : {}
            }
        })

        const { res } = context

        const wappResponse = res.wappResponse
        const route = wappResponse.route
        const { params } = route
        const { pageType } = params

        //const {userStatusManager} = appContext;

        function addMenuItems(props) {

            const { appContext /*statusManager*/ } = props
            const { menus, routes /*userStatusManager*/ } = appContext

            return [
                {
                    label: function(p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                        return (isAuthor) ? menus['my' + Ns + 'Menu'] : menus[postContext.name + Ns + 'Menu']
                    },
                    role: function(p) {
                        const viewerIsAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                        const viewerIsAdmin = (p.user?._id && p.user._status_isFeatured)
                        const postIsAdmin = (p.post && p.post._status_isFeatured)
                        return (postIsAdmin && (viewerIsAuthor || viewerIsAdmin))
                    },
                    order: 50,
                    items: [
                        {
                            label: menus['new' + N + 'Menu'],
                            href: routes[n + 'Route'] + '/new',
                            role: function(p) {
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                const isAdmin = p.user && p.user._status_isFeatured
                                return !!(isAdmin && isAuthor)
                            },
                            disableParentRoute: true
                        },
                        {
                            label: function(p) {
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return (isAuthor) ? menus['my' + Ns + 'Menu'] : menus[postContext.name + Ns + 'Menu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] : routes[postContext.name + Ns + 'Route']
                            },
                            role: function(p) {
                                return ((p.user && p.user._status_isFeatured) || (p.post && p.post._status_isFeatured))
                            }
                        },
                        {
                            divider: true,
                            role: function(p) {
                                return p.user && p.user._status_isFeatured
                            }
                        },
                        {
                            label: function() {
                                return menus['deleted' + Ns + 'Menu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] + '/deleted' : routes[postContext.name + Ns + 'Route'] + '/deleted'
                            },
                            role: function(p) {
                                const isAuthor = ((p?.user?._id && p?.user?._id === p?.post?._author) || (p?.user?._id && p?.user?._id === p?.post?._author?._id))
                                const isAdmin = p.user && p.user._status_isFeatured
                                return isAuthor || isAdmin
                            }
                        }
                    ]
                }
            ]
        }

        function addContentMenuItems() {
            return [
                {
                    Component: Overview,
                    role: function(p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                        const isAdmin = p.user && p.user._status_isFeatured
                        return !!(isAdmin && isAuthor)
                    },
                    order: 11
                }
            ]
        }

        function getPageName({ user, post, page /*statusManager*/ }) {

            const isAdmin = user && user._status_isFeatured
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id))
            const isAuthorOrAdmin = (isAdmin || isAuthor)
            const authorIsAdmin = post && post._status_isFeatured

            return (
                (page === ns && !pageType) ||
                (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1) ||
                (page === ns && pageType === 'deleted' && isAuthorOrAdmin) ||
                (page === ns && pageType === 'banned' && isAdmin)
            ) ? (isAdmin || authorIsAdmin) ? page : 'notFound' : null

        }

        return {
            ...r,
            addMenuItems,
            addContentMenuItems,
            getPageName
        }
    },
    adminMenu: function(p) {
        const name = 'product'
        const defaultAdminMenu = defaultPostTypeConfig.adminMenu({ ...p, name })
        defaultAdminMenu.order = 50
        return defaultAdminMenu
    }
}

export default postTypeConfig
