import React from 'react'

import getUtils from 'wapplr-react/dist/common/Wapp/getUtils'
import { copyObject } from 'wapplr/dist/common/utils'

import Typography from 'octopwn-ui/dist/common/src/components/Typography'
import Label from 'octopwn-ui/dist/common/src/components/Label'
import StarIcon from 'octopwn-ui/dist/common/src/svg/StarIcon'
import Paper from 'octopwn-ui/dist/common/src/components/Paper'

import AddToCart from '../../order/Cart/AddToCart'
import PriceByCurrency from '../PriceByCurrency'
import Price from '../PriceByCurrency/Price'

export function transformPosts({ posts, editable, classNames, appContext, context, disableAddToCart }) {

    const utils = getUtils(context)
    const user = utils.getRequestUser()
    const orderPostTypeName = 'order'
    const storageName = user?._id ? orderPostTypeName + '_' + user._id : orderPostTypeName

    const { storage } = appContext

    function getStorageData() {
        const localStorageData = storage()[storageName] || {}
        const memoData = storage(undefined, true)[storageName] || {}
        return { ...localStorageData, ...memoData }
    }

    const storageData = getStorageData()
    const cart = (storageData['record.cart']) ? JSON.parse(storageData['record.cart']) : []
    const newPosts = copyObject(posts)
    cart.forEach((cartItem) => {
        const post = (cartItem?._id && cartItem?.q) ? newPosts.find((p) => p._id === cartItem._id) : null
        if (post) {
            post.q = cartItem.q
        }
    })
    newPosts.forEach((post) => {
        if (post?.q) {
            const cartItem = cart.find((cartItem) => cartItem?._id === post._id)
            if (!cartItem?.q) {
                post.q = 0
            }
        }
    })

    return newPosts.map((post) => {

        const { discountText, infoText, featuredChildren, ...rest } = post

        const r = {
            _original: () => {
                return {
                    discountText,
                    infoText,
                    featuredChildren,
                    ...rest
                }
            },
            ...rest,
            contentExtract: <div className={classNames.productContent}>
                {featuredChildren?.length ?
                    <div className={classNames.productsFeatured}>
                        {featuredChildren.map((menu, i) => <Label
                            key={i}
                            label={menu.label}
                            color={menu.color}
                            startIcon={<StarIcon />}
                        />)
                        }
                    </div> : null
                }
                {discountText ?
                    <Paper
                        color={'featured'}
                        variant={'text'}
                        size={'none'}
                        elevation={0}
                    >
                        <Typography variant={'h6'}
                                    weight={600}>{discountText}</Typography>
                    </Paper> : null
                }
                {!editable ?
                    <Price product={post} /> :
                    <PriceByCurrency product={post} />
                }
                {infoText ?
                    <div className={classNames.infoText}>
                        <Paper
                            variant={'contained'}
                            size={'none'}
                            elevation={0}
                        >
                            <Typography variant={'body'} weight={400}>{infoText}</Typography>
                        </Paper>
                    </div>
                    : null
                }
            </div>,
            MenuItemProps: {
                enableDivider: false,
                TitleProps: {
                    variant: 'h2',
                    weight: 400,
                    className: classNames.pricesItemTitle
                },
                SubtitleProps: {
                    className: classNames.pricesItemSubtitle
                },
                ContentComponentProps: {
                    ...post.thumb ? {
                        ImageProps: {
                            className: classNames.cardImage
                        }
                    } : {},
                    ContentComponentProps: {
                        style: {
                            position: 'unset'
                        }
                    }
                }
            }
        }

        if (!disableAddToCart) {
            r.MenuItemProps.ContentComponentProps.footerContent = <>
                <AddToCart
                    post={r}
                    onChange={async (p) => {

                        let post = { ...p.post }
                        if (post._original) {
                            post = {
                                ...post,
                                ...post._original()
                            }
                        }

                        const storageData = storage()[storageName] || {}
                        if (post?._id) {
                            const cart = (storageData['record.cart']) ? JSON.parse(storageData['record.cart']) : []
                            const cartItem = cart.find((cartItem) => cartItem?._id === post._id)
                            if (cartItem) {
                                if (cartItem.q !== p.q) {
                                    cartItem.q = p.q
                                    const filteredCart = cart.filter((cartItem) => cartItem.q > 0)
                                    storage({
                                        [storageName]: {
                                            ...storageData,
                                            ['record.cart']: JSON.stringify(filteredCart)
                                        }
                                    })
                                    await appContext.template.current?.actions?.updateMiniCart()
                                }
                            } else {

                                const enableKeys = [
                                    'price',
                                    'priceText',
                                    'price1',
                                    'price2',
                                    'price1Usd',
                                    'price2Usd',
                                    'price1Chf',
                                    'price2Chf',
                                    'currency',
                                    'currencyText',
                                    'q',
                                    'subtitle',
                                    '_id',
                                    'thumb',
                                    'title',
                                    'subtitle',
                                    'disableProduct',
                                    'available',
                                    'paperColor',
                                    'infoText',
                                    'infoTextShort'
                                ]

                                cart.push({
                                    ...enableKeys.reduce((cartItem, key) => {
                                        cartItem[key] = post[key]
                                        return cartItem
                                    }, {})
                                })

                                const filteredCart = cart.filter((cartItem) => cartItem.q > 0)
                                storage({
                                    [storageName]: {
                                        ...storageData,
                                        ['record.cart']: JSON.stringify(filteredCart)
                                    }
                                })
                                await appContext.template.current?.actions?.updateMiniCart()
                            }
                        }
                    }}
                />
            </>

        }

        return r
    })
}
