import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const name = 'license'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Save',
            ['restore' + N + 'SubmitLabel']: 'Restore',
            ['save' + N + 'SubmitLabel']: 'Save',
            ['cancel' + N + 'Text']: 'Cancel',
            ['delete' + N + 'Text']: 'Delete',
            ['delete' + Ns + 'Text']: 'Delete licenses',
            ['ban' + N + 'Text']: 'Ban',
            ['approve' + N + 'Text']: 'Approve',
            ['protection' + N + 'Text']: 'Mark as protected',
            ['removeProtection' + N + 'Text']: 'Remove protection',
            ['select' + N + 'Text']: 'Select',
            ['add' + N + 'Text']: 'Add',
            [ns + 'SortRelevance']: 'Relevance',

            ['date' + N + 'Format']: ({ dateText = '' }) => {
                if (!dateText) {
                    return dateText
                }
                try {
                    return new Date(dateText).toLocaleString('en-US')
                } catch (e) {
                }
                return dateText
            },

            [n + 'TitleLabel']: 'License',

            [n + 'SearchLabel']: 'License ID',
            [n + 'SearchLabelForAdmin']: 'License ID',

            ['moreFromMy' + Ns + 'Title']: 'More licenses',
            ['moreFromUser' + Ns + 'Title']: 'More licenses',

            [n + 'InviteUserByEmailCancelLabel']: 'Cancel',
            [n + 'InviteUserByEmailSubmitLabel']: 'Invite'
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Licenses',
            ['my' + Ns + 'Menu']: 'My licenses',
            ['new' + N + 'Menu']: 'New license',
            [ns + 'Menu']: 'Licenses',
            ['deleted' + Ns + 'Menu']: 'Deleted licenses',
            ['banned' + Ns + 'Menu']: 'Permanently deleted licenses',
            ['protection' + Ns + 'Menu']: 'Protected licenses',
            ['missingData' + Ns + 'Menu']: 'Missing data',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Author with low status',

            ['edit' + N + 'Menu']: 'Edit',
            ['delete' + N + 'Menu']: 'Delete',
            ['ban' + N + 'Menu']: 'Ban',
            ['approve' + N + 'Menu']: 'Approve',
            ['protection' + N + 'Menu']: 'Mark as protected',
            ['removeProtection' + N + 'Menu']: 'Remove protection',

            ['user' + Ns + 'OwnedMenu']: 'Owned licenses',
            ['my' + Ns + 'OwnedMenu']: 'Owned licenses',
            ['user' + Ns + 'ForMeMenu']: 'Assigned licenses',
            ['my' + Ns + 'ForMeMenu']: 'Assigned licenses'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'The new license has been saved',
            ['save' + N + 'SuccessMessage']: 'The license has been saved',
            ['delete' + N + 'Success']: 'The license has been deleted',
            ['delete' + Ns + 'Success']: 'The licenses have been deleted',
            ['delete' + N + 'Question']: 'Are you sure you want to delete the license?',
            ['delete' + Ns + 'Question']: 'Are you sure you want to delete the licenses?',
            ['ban' + N + 'Success']: 'The license has been permanently deleted',
            ['ban' + N + 'Question']: 'Are you sure you want to permanently delete the license?',
            ['approve' + N + 'Success']: 'The license has been approved',
            ['approve' + N + 'Question']: 'Are you sure you want to approve the license?',
            ['protection' + N + 'Success']: 'The license has been marked as protected',
            ['protection' + N + 'Question']: 'Are you sure you want to mark the license as protected?',
            ['removeProtection' + N + 'Success']: 'The protection has been removed',
            ['removeProtection' + N + 'Question']: 'Are you sure you want to remove the protection?',
            ['thereAreNo' + Ns]: 'There are no licenses',

            [n + 'CopyClipboardSuccess']: 'Link copied to clipboard',
            [n + 'CopyClipboardError']: 'Clipboard copying is not supported on this device',

            [n + 'InviteUserByEmailSuccessMessage']: 'License usage invitation has been sent',
            [n + 'InviteUserByEmailOwnUsageSuccessMessage']: 'Your license has been linked to this account',

            thisIsNotYourLicenseInviteConfirmationKey: 'This license invite confirmation key not belong to this account, please log out'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'License',
            [ns + 'Title']: 'Licenses',
            ['new' + N + 'Title']: 'New license',
            ['edit' + N + 'Title']: 'Edit license',
            ['user' + Ns + 'Title']: 'Licenses',
            ['userDeleted' + Ns + 'Title']: 'Deleted licenses',
            ['my' + Ns + 'Title']: 'Owned licenses',
            ['myDeleted' + Ns + 'Title']: 'Deleted my licenses',

            ['dialogDelete' + N + 'Title']: 'Delete license',
            ['dialogBan' + N + 'Title']: 'Permanently delete license',
            ['dialogApprove' + N + 'Title']: 'Approve license',
            ['dialogProtection' + N + 'Title']: 'Mark license as protected',
            ['dialogRemoveProtection' + N + 'Title']: 'Remove license protection',

            ['dialogDelete' + Ns + 'Title']: 'Delete licenses',

            ['statusBanned' + N + 'Title']: 'Permanently deleted',
            ['statusDeleted' + N + 'Title']: 'Deleted',
            ['statusAuthorDeleted' + N + 'Title']: 'Author deleted',
            ['statusMissingData' + N + 'Title']: 'Missing data',
            ['statusApproved' + N + 'Title']: 'Approved',
            ['statusFeatured' + N + 'Title']: 'Protected',
            ['statusCreated' + N + 'Title']: 'Created',

            ['banned' + Ns + 'Title']: 'Permanently deleted licenses',
            ['deleted' + Ns + 'Title']: 'Deleted licenses',
            ['missingData' + Ns + 'Title']: 'Missing data licenses',
            ['protected' + Ns + 'Title']: 'Protected licenses',
            ['lowAuthorStatus' + Ns + 'Title']: 'Author with low status licenses',

            ['select' + Ns]: 'Select licenses',
            ['select' + N]: 'Select license',

            ['my' + Ns + 'ForMeTitle']: 'Assigned licenses',
            ['user' + Ns + 'ForMeTitle']: 'Assigned licenses',

            [n + 'InviteUserByEmailTitle']: 'Send license usage invitation'
        }
    }
}
