import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const name = 'email'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Save',
            ['restore' + N + 'SubmitLabel']: 'Restore',
            ['save' + N + 'SubmitLabel']: 'Save',
            ['cancel' + N + 'Text']: 'Cancel',
            ['delete' + N + 'Text']: 'Delete',
            ['delete' + Ns + 'Text']: 'Delete email templates',
            ['ban' + N + 'Text']: 'Ban',
            ['approve' + N + 'Text']: 'Approve',
            ['protection' + N + 'Text']: 'Mark as protected',
            ['removeProtection' + N + 'Text']: 'Remove protection',
            ['select' + N + 'Text']: 'Select',
            ['add' + N + 'Text']: 'Add',

            [ns + 'SortRelevance']: 'Relevance',

            ['test' + N + 'SubmitLabel']: 'Send test email',
            ['send' + N + 'SubmitLabel']: 'Send newsletter',

            [n + 'InquirySubmitLabel']: 'Send message'
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Email templates',
            ['my' + Ns + 'Menu']: 'My email templates',
            ['new' + N + 'Menu']: 'New email template',
            [ns + 'Menu']: 'Email templates',
            ['deleted' + Ns + 'Menu']: 'Deleted email templates',
            ['banned' + Ns + 'Menu']: 'Permanently deleted email templates',
            ['protection' + Ns + 'Menu']: 'Protected email templates',
            ['missingData' + Ns + 'Menu']: 'Missing data',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Author with low status',

            ['edit' + N + 'Menu']: 'Edit',
            ['delete' + N + 'Menu']: 'Delete',
            ['ban' + N + 'Menu']: 'Ban',
            ['approve' + N + 'Menu']: 'Approve',
            ['protection' + N + 'Menu']: 'Mark as protected',
            ['removeProtection' + N + 'Menu']: 'Remove protection'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'The new email template has been saved',
            ['save' + N + 'SuccessMessage']: 'The email has been saved',
            ['delete' + N + 'Success']: 'The email template has been deleted',
            ['delete' + Ns + 'Success']: 'The email templates have been deleted',
            ['delete' + N + 'Question']: 'Are you sure you want to delete the email template?',
            ['delete' + Ns + 'Question']: 'Are you sure you want to delete the email templates?',
            ['ban' + N + 'Success']: 'The email template has been permanently deleted',
            ['ban' + N + 'Question']: 'Are you sure you want to permanently delete the email template?',
            ['approve' + N + 'Success']: 'The email template has been approved',
            ['approve' + N + 'Question']: 'Are you sure you want to approve the email template?',
            ['protection' + N + 'Success']: 'The email template has been marked as protected',
            ['protection' + N + 'Question']: 'Are you sure you want to mark the email template as protected?',
            ['removeProtection' + N + 'Success']: 'The protection has been removed',
            ['removeProtection' + N + 'Question']: 'Are you sure you want to remove the protection?',
            ['thereAreNo' + Ns]: 'There are no email templates',

            ['test' + N + 'SuccessMessage']: 'Test email sent',
            ['send' + N + 'SuccessMessage']: 'Newsletter sent',

            [n + 'InquirySuccessMessage']: 'Your message has been sent.\nThank you!'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Email template',
            [ns + 'Title']: 'Email templates',
            ['new' + N + 'Title']: 'New email template',
            ['edit' + N + 'Title']: 'Edit email template',
            ['user' + Ns + 'Title']: 'Email templates',
            ['userDeleted' + Ns + 'Title']: 'Deleted email templates',
            ['userBanned' + Ns + 'Title']: 'Permanently deleted email templates',
            ['my' + Ns + 'Title']: 'My email templates',
            ['myDeleted' + Ns + 'Title']: 'Deleted my email templates',
            ['myBanned' + Ns + 'Title']: 'Permanently deleted my email templates',

            ['dialogDelete' + N + 'Title']: 'Delete email template',
            ['dialogBan' + N + 'Title']: 'Permanently delete email template',
            ['dialogApprove' + N + 'Title']: 'Approve email template',
            ['dialogProtection' + N + 'Title']: 'Mark email template as protected',
            ['dialogRemoveProtection' + N + 'Title']: 'Remove email template protection',

            ['dialogDelete' + Ns + 'Title']: 'Delete email templates',

            ['statusBanned' + N + 'Title']: 'Permanently deleted',
            ['statusDeleted' + N + 'Title']: 'Deleted',
            ['statusAuthorDeleted' + N + 'Title']: 'Author deleted',
            ['statusMissingData' + N + 'Title']: 'Missing data',
            ['statusApproved' + N + 'Title']: 'Approved',
            ['statusFeatured' + N + 'Title']: 'Protected',
            ['statusCreated' + N + 'Title']: 'Created',

            ['banned' + Ns + 'Title']: 'Permanently deleted email templates',
            ['deleted' + Ns + 'Title']: 'Deleted email templates',
            ['missingData' + Ns + 'Title']: 'Missing data',
            ['protected' + Ns + 'Title']: 'Protected email templates',
            ['lowAuthorStatus' + Ns + 'Title']: 'Author with low status',

            ['select' + Ns]: 'Select email templates',
            ['select' + N]: 'Select email template'
        }
    }
}
