import getDefaultMenu from '../../components/Post/menu'

function getMenu(props = {}) {
    return getDefaultMenu(props).map((menu, i) => {
        if (i === 0 || i === 2) {
            const role = menu.role
            return {
                ...menu,
                role: (p) => {
                    const isAdmin = p.user && p.user._status_isFeatured
                    return isAdmin && role(p)
                }
            }
        }
        return null
    }).filter((menu) => menu)
}

export default getMenu
