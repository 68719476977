import React from 'react'

import Container from 'octopwn-ui/dist/common/src/components/Container'

import capitalize from '../../utils/capitalize'

import defaultPostTypeConfig from '../post'
import { runPostTypesConfig } from '../index'

import getConstants from './constants'

import FooterSocial from './Content/FooterSocial'

import Content from './Content'
import New from './New'
import Edit from './Edit'

import { replaceContentForArchivePage } from '../post/contents'

const postTypeConfig = {
    getStatusManager: function getStatusManager(p = {}) {
        return defaultPostTypeConfig.getStatusManager({
            ...p,
            config: {
                requiredDataForStatus: {
                    title: { type: String }
                },
                ...(p.config) ? p.config : {}
            }
        })
    },
    getConstants: getConstants,
    setContents: function setContents(p = {}) {

        const { wapp, routes } = p

        const name = 'page'
        const n = name
        const N = capitalize(n)

        const r = defaultPostTypeConfig.setContents({
            ...p,
            name,
            urlParams: [
                '/page/:pagination',
                '/sort/:sort',
                '/limit/:limit'
            ]
        })

        let reqUserForPost = null

        const slugPattern = /^[a-z\d](-?[a-z\d]){1,150}$/
        const objectIdPattern = /^[\da-fA-F]{24}$/

        const contentProps = wapp.contents.get(name)

        wapp.contents.handle = async function defaultHandle(req, res, next) {
            if (!res.wappResponse.content) {
                const contentRes = wapp.contents.contentManager.resolve
                const content = await contentRes({ route: res.wappResponse.route, wapp, req, res })
                if (!res.wappResponse.content) {
                    res.wappResponse.content = content
                }
            }
            return next()
        }

        wapp.contents.add({
            [name]: {
                ...contentProps,
                ComponentProps: {
                    TemplateProps: {
                        AfterChildren: FooterSocial,
                        AfterChildrenProps: {}
                    }
                },
                request: async function({ wapp, req, res }) {

                    const wappResponse = res.wappResponse

                    const route = wappResponse.route
                    const { params } = route
                    const { _id } = params
                    const slug = params.slug && params.slug.match(slugPattern) ? params.slug : null
                    const isSlugRequest = slug ? true : !_id?.match(objectIdPattern) && _id?.match(slugPattern)
                    const requestName = slug ? name + 'FindBySlug' : _id?.match(objectIdPattern) ? name + 'FindById' : isSlugRequest ? name + 'FindBySlug' : null

                    const statusManager = wapp.getTargetObject().postTypes.findPostType({ name: n }).statusManager
                    await runPostTypesConfig({
                        action: 'requestFor' + N + 'Page',
                        p: { wapp, req, res, statusManager }
                    })

                    if (requestName) {

                        const posts = [wappResponse.store.getState('res.responses.' + name + 'FindById'), wappResponse.store.getState('res.responses.' + name + 'FindBySlug')]
                        const post = posts.find((p) => isSlugRequest ? slug ? (p?.slug === slug) : (p?.slug === _id) : p?._id === _id)

                        const args = isSlugRequest ? { slug: slug ? slug : _id } : { _id }

                        let response

                        if (
                            (
                                (reqUserForPost?._id !== req.wappRequest.user?._id) ||
                                (_id && !post) ||
                                (!isSlugRequest && post?._id !== _id) ||
                                (!slug && isSlugRequest && post?.slug !== _id) ||
                                (slug && isSlugRequest && post?.slug !== slug)
                            )
                            && args.slug !== 'upload'
                            && args.slug !== 'assets'
                            && args.slug !== 'thumb'
                            && args.slug !== 'getthumb'
                            && args.slug !== 'pay-now'
                            && args.slug !== 'wasm-login'
                            && args.slug !== 'plugins-for-cert'
                            && !(args.slug && args.slug.startsWith('download-'))
                        ) {
                            reqUserForPost = { _id: req.wappRequest.user?._id }
                            if (wapp.globals.DEV && slug) {
                                console.log('[APP] Trying to find a post with this slug: ', slug)
                            }
                            response = await wapp.requests.send({ requestName, args, req, res })
                        } else {
                            response = {
                                [requestName]: post
                            }
                        }

                        if (response && response[requestName]?._id && isSlugRequest && slug) {

                            const replacePath = response[requestName].replacePath
                            const requestParamSearchWords = response[requestName].requestParamSearchWords
                            const requestParamsReplace = response[requestName].requestParams

                            if (replacePath) {

                                function transformRequestParams({ requestParams }) {

                                    const latinGroups = [
                                        ['a', 'á'],
                                        ['e', 'é'],
                                        ['i', 'í'],
                                        ['i', 'í'],
                                        ['o', 'ó', 'ö', 'ő'],
                                        ['u', 'ú', 'ü', 'ű']
                                    ]

                                    const contentString = requestParamSearchWords ?
                                        requestParamSearchWords
                                            .split(' ')
                                            .filter((t) => t)
                                            .map((t) => {
                                                return t.split('').reduce((t, char) => {
                                                    const group = latinGroups.find((g) => g.indexOf(char) > -1)
                                                    if (group) {
                                                        t = t + `[${group.join('|')}]`
                                                    } else {
                                                        t = t + char
                                                    }
                                                    return t
                                                }, '')
                                            })
                                            .join('|')
                                        : ''

                                    if (contentString) {
                                        requestParams.args.filter._operators._search = { regex: `/(^|[\\s"])(${contentString})(?![^\\s"])/gmi` }
                                    }

                                    if (requestParamsReplace) {

                                        let requestParamsReplaceObject
                                        try {
                                            requestParamsReplaceObject = JSON.parse(requestParamsReplace)
                                        } catch (e) {
                                        }

                                        if (requestParamsReplaceObject) {

                                            function rec(o, c) {
                                                Object.keys(o).forEach((key) => {
                                                    if (o[key] && typeof o[key] === 'object' && !Array.isArray(o[key])) {
                                                        if (!Array.isArray(o[key])) {
                                                            if (!c[key]) {
                                                                c[key] = {}
                                                            }
                                                            rec(o[key], c[key])
                                                        }
                                                    } else {
                                                        c[key] = o[key]
                                                    }
                                                })
                                            }

                                            rec(requestParamsReplaceObject, requestParams)

                                        }
                                    }

                                    return requestParams
                                }

                                const defaultPath = (route.requestPath.startsWith(routes[name + 'Route']) ? routes[name + 'Route'] : '') + slug ? '/' + route.params.slug : route.params._id

                                function transformResForTitle(res) {
                                    return {
                                        ...res,
                                        wappResponse: {
                                            ...res.wappResponse,
                                            route
                                        }
                                    }
                                }

                                await replaceContentForArchivePage({
                                    wapp,
                                    req,
                                    res,
                                    defaultPath,
                                    replacePath,
                                    transformRequestParams,
                                    transformResForTitle
                                })

                            }
                        }

                        return response

                    }
                }
            }
        })
        return r
    },
    requestForUserPage: async function requestForUserPage(p = {}) {
        return await defaultPostTypeConfig.requestForUserPage({
            ...p,
            name: 'page'
        })
    },
    getComponent: function(p) {

        const name = 'page'
        const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'

        const { context, appContext } = p
        const { routes /*userStatusManager*/ } = appContext

        const { res, req } = context
        const route = res.wappResponse.route
        const requestPath = route.requestPath
        const user = req.wappRequest.user
        const { params } = route

        const slugPattern = /^[a-z\d](-?[a-z\d]){1,150}$/
        const slug = params.slug && params.slug.match(slugPattern) ? params.slug : null

        const isAdmin = user && user._status_isFeatured

        if (
            (requestPath.startsWith(routes[name + 'Route'])) ||
            (requestPath.startsWith(routes[ns + 'Route'])) ||
            slug
        ) {

            const pages = {
                content: Content,
                edit: Edit,
                new: New
            }

            if (slug) {
                res.wappResponse.route.requestPath = routes[name + 'Route']
            }

            if (requestPath === routes[name + 'Route'] + '/new' && !isAdmin && user) {
                return defaultPostTypeConfig.getComponent({
                    ...p,
                    name,
                    componentProps: { pages, getPageName: () => 'notFound', layoutType: 'article' }
                })
            }

            if (requestPath.startsWith(routes[ns + 'Route']) && !isAdmin) {
                return null
            }

            const requestKeys = [name + 'FindById', name + 'FindBySlug']
            const slugPattern = /^[a-z\d](-?[a-z\d]){1,150}$/
            const objectIdPattern = /^[\da-fA-F]{24}$/

            return defaultPostTypeConfig.getComponent({
                ...p,
                name,
                archiveComponentProps: {
                    selectable: false
                },
                componentProps: {
                    pages,
                    showAuthor: ({ user }) => user?._status_isFeatured,
                    //showCreatedDate: ({ user, author }) => user?._id && user._id === author?._id,
                    getProps: ({ pageName, post }) => {

                        if (pageName === 'content') {

                            return {
                                showAuthor: () => false,
                                PaperComponentProps: {
                                    color: 'default',
                                    elevation: 0,
                                    style: {
                                        padding: 0,
                                        backgroundColor: 'transparent',
                                        ...post?.layoutType === 'default' ? {
                                            maxWidth: "960px",
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                        } : {}
                                    }
                                },
                                TitleComponentProps: {
                                    variant: 'h3'
                                },
                                ...post?.layoutType === 'page' ? {
                                    disableTitleContainer: true,
                                    getMenu: () => [],
                                    PageContainer: ({ ...props }) => {
                                        return <Container {...props} container-type={''} />
                                    }
                                } : {
                                    disableTitleContainer: true,
                                }
                            }

                        }

                        return {}
                    },
                    requestKeys,
                    getInitialResponse: function() {

                        const wappResponse = res.wappResponse
                        const route = wappResponse.route
                        const { params } = route
                        const { _id, slug } = params

                        const isSlugRequest = slug ? true : !_id?.match(objectIdPattern) && _id?.match(slugPattern)
                        const requestName = slug ? name + 'FindBySlug' : _id?.match(objectIdPattern) ? name + 'FindById' : isSlugRequest ? name + 'FindBySlug' : null

                        if (requestName) {
                            const posts = [wappResponse.store.getState('res.responses.' + name + 'FindById'), wappResponse.store.getState('res.responses.' + name + 'FindBySlug')]
                            return posts.find((p) => isSlugRequest ? (slug ? (p?.slug === slug) : (p?.slug === _id)) : p?._id === _id)
                        }

                        return null

                    }
                }
            })
        }
    },
    userPageFunctions: function(p) {

        const { context, /*appContext,*/ postContext } = p

        const n = 'page'
        const ns = (n.endsWith('y')) ? n.slice(0, -1) + 'ies' : n + 's'
        const N = capitalize(n)
        const Ns = capitalize(ns)

        const r = defaultPostTypeConfig.userPageFunctions({
            ...p,
            name: n,
            nsPageProps: {
                selectable: false,
                type: 'cardList',
                disablePageInfo: true, ...p.nsPageProps ? p.nsPageProps : {}
            }
        })

        const { res } = context

        const wappResponse = res.wappResponse
        const route = wappResponse.route
        const { params } = route
        const { pageType } = params

        //const {userStatusManager} = appContext;

        function addMenuItems(props) {

            const { appContext /*statusManager*/ } = props
            const { menus, routes /*userStatusManager*/ } = appContext

            return [
                {
                    label: function(p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                        return (isAuthor) ? menus['my' + Ns + 'Menu'] : menus[postContext.name + Ns + 'Menu']
                    },
                    role: function(p) {
                        const viewerIsAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                        const viewerIsAdmin = (p.user?._id && p.user._status_isFeatured)
                        const postIsAdmin = (p.post && p.post._status_isFeatured)
                        return (postIsAdmin && (viewerIsAuthor || viewerIsAdmin))
                    },
                    order: 70,
                    items: [
                        {
                            label: menus['new' + N + 'Menu'],
                            href: routes[n + 'Route'] + '/new',
                            role: function(p) {
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                const isAdmin = p.user && p.user._status_isFeatured
                                return !!(isAdmin && isAuthor)
                            },
                            disableParentRoute: true
                        },
                        {
                            label: function(p) {
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return (isAuthor) ? menus['my' + Ns + 'Menu'] : menus[postContext.name + Ns + 'Menu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] : routes[postContext.name + Ns + 'Route']
                            },
                            role: function(p) {
                                return ((p.user && p.user._status_isFeatured) || (p.post && p.post._status_isFeatured))
                            }
                        },
                        {
                            divider: true,
                            role: function(p) {
                                return p.user && p.user._status_isFeatured
                            }
                        },
                        {
                            label: function() {
                                return menus['deleted' + Ns + 'Menu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] + '/deleted' : routes[postContext.name + Ns + 'Route'] + '/deleted'
                            },
                            role: function(p) {
                                const isAuthor = ((p?.user?._id && p?.user?._id === p?.post?._author) || (p?.user?._id && p?.user?._id === p?.post?._author?._id))
                                const isAdmin = p.user && p.user._status_isFeatured
                                return isAuthor || isAdmin
                            }
                        }
                    ]
                }
            ]
        }

        //const parentRoute = appContext.routes[postContext.name + 'Route']

        function addContentMenuItems() {
            return [
                /*{
                    role: function(p) {
                        return (p.post && p.post._status_isFeatured)
                    },
                    order: 70,
                    Component: () => {

                        const postContext = useContext(PostContext)

                        const { user, post } = postContext

                        const isAuthor = ((user?._id && user?._id === post?._author) || (user?._id && user?._id === post?._author?._id))

                        return (
                            <Posts
                                name={n}
                                type={'cardList'}
                                label={isAuthor ? appContext.titles['my' + Ns + 'Title'] : appContext.titles['user' + Ns + 'Title']}
                                nextLabel={isAuthor ? appContext.labels['moreFromMy' + Ns + 'Title'] : appContext.labels['moreFromUser' + Ns + 'Title']}
                                LabelProps={{
                                    variant: 'h5',
                                    weight: 700
                                }}
                                requestParams={{
                                    page: 1,
                                    perPage: 2,
                                    _author: post?._id
                                }}
                                posts={[]}
                                postsSlice={[]}
                                fromRequest={true}
                                enableCache={false}
                                carousel={false}
                                NextProps={{
                                    href: parentRoute + ((post?._id) ? '/' + post._id + appContext.routes[postContext.name + Ns + 'Route'] : appContext.routes[postContext.name + Ns + 'Route']),
                                    color: undefined,
                                    size: 'default',
                                    variant: 'text'
                                }}
                                NextButtonContainerProps={{
                                    style: {
                                        justifyContent: 'flex-end'
                                    }
                                }}
                                disableMenu={false}
                            />
                        )
                    }
                }*/
            ]
        }

        function getPageName({ user, post, page /*statusManager*/ }) {

            const isAdmin = user && user._status_isFeatured
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id))
            const isAuthorOrAdmin = (isAdmin || isAuthor)
            const authorIsAdmin = post && post._status_isFeatured

            return (
                (page === ns && !pageType) ||
                (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1) ||
                (page === ns && pageType === 'deleted' && isAuthorOrAdmin) ||
                (page === ns && pageType === 'banned' && isAdmin)
            ) ? (isAdmin || authorIsAdmin) ? page : 'notFound' : null

        }

        return {
            ...r,
            addMenuItems,
            addContentMenuItems,
            getPageName
        }
    },
    adminMenu: function(p) {
        const name = 'page'
        const defaultAdminMenu = defaultPostTypeConfig.adminMenu({ ...p, name })
        defaultAdminMenu.order = 70
        return defaultAdminMenu
    }
}

export default postTypeConfig
